@media (min-width: 567px) {
    .testimonials-cards-wrapper .card-columns {
        column-count: 1;
    }
}

@media (min-width: 768px) {
    .testimonials-cards-wrapper .card-columns {
        column-count: 2;
    }
}

@media (min-width: 1366px) {
    .container {
        max-width: 1250px;
    }

    .container-header {
        max-width: 100%;
    }

    .container2 {
        max-width: 100%;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .news-events-wrapper .container {
        max-width: 960px;
    }
}


/* @media (min-width: 576px) {
	.news-events-wrapper .container {
		max-width: 540px;
	}
}
@media (min-width: 768px) {
	.news-events-wrapper .container {
		max-width: 720px;
	}
} */

@media (max-width:1366px) {
    .online-consultation-wrapper .container:after {
        right: -15px;
    }

    .newshead-wrapper {
        padding: 30px 0;
    }

    .news-head-section p {
        padding: 0 5px;
    }

    .video-play-wrapper>img {
        height: 100%;
    }
}

@media (max-width:1199px) {
    .menu a {
        font-size: 14px;
        margin: 0 0 0 8px;
        text-transform: none;
    }

    .newshead-wrapper {
        padding: 28px 0;
    }

    .news-events-head-wrapper {
        width: 30%;
    }

    .newsevents-slider-wrapper {
        width: 70%;
    }

    .news-icon {
        width: 71px;
        height: 71px;
    }

    .foreign-guests-image {
        width: 30%;
    }

    .foreign-guests-contents {
        width: 70%;
    }

    .foreign-guests-image,
    .photo-oftheday-slider,
    .photo-oftheday-slider-img img {
        min-height: 300px;
    }

    .section-title h2 {
        font-size: 24px;
    }

    .healthtips-wrapper .card>img {
        height: 170px;
        width: 100%;
    }

    .healthtips-wrapper .card-body {
        padding: 15px;
    }

    .healthtips-card .card-title {
        border-color: #e54397;
        font-size: 16px;
        margin: 0 0 5px;
    }

    .healthtips-card .card-text {
        font-size: 13px;
        font-weight: 300;
    }

    .news-events-card {
        box-shadow: none;
        width: 100%;
    }

    .online-consultation-main-wrapper {
        height: 350px;
        ;
    }



    .services-icon img.small-icon {
        max-width: 40px;
    }

    .services-contetnt {
        width: 67%;
    }

    .services-contetnt h5 {
        font-size: 13px;
    }

}

@media (max-width: 575px){
    .news-events-card {
        box-shadow: none;
        width: 80%;
        position: relative;
        left: 50%;
        transform: translateX(-50%)
    }
}

@media (min-width:1400px) and (max-width:1560px) {}

@media (min-width:1200px) and (max-width:1399px) {}

@media (max-width:1199px) {
    header {}

    .specs-wrap h5 {}
}

@media (max-width:991px) {
    .toggle-btn {
        display: block;
        height: 28px;
        /* height: 43px; */
        color: #222222;
        /* color: #fff; */
        line-height: 40px;
        font-size: 1.2em;
        width: 40px;
        text-align: center;
        /* background: #fff; */
        margin: 0;
        z-index: 9;
        position: absolute;
        right: 25px;
        top: 15px;
        display: flex;
        align-items: center;
        border-radius: 50%;
    }

    span.toggle-lines {
        width: 30px;
        margin: 0 auto;
        height: 2px;
        display: inline-block;
        background: #fff;
        /* background: #333; */
        position: relative;
    }

    span.toggle-lines:before,
    span.toggle-lines:after {
        position: absolute;
        content: "";
        width: 30px;
        height: 2px;
        background: #fff;
        -webkit-transition: ease .2s;
        -o-transition: ease .2s;
        transition: ease .2s;
    }

    span.toggle-lines:before {
        right: 0;
        top: -7px;
        width: 30px
    }

    span.toggle-lines:after {
        right: 0;
        bottom: -7px;
        width: 30px;
    }

    .toggle-btn.active-toggle span.toggle-lines {
        height: 0;
    }

    .toggle-btn.active-toggle span.toggle-lines:before,
    .toggle-btn.active-toggle span.toggle-lines:after {
        width: 30px;
        width: 23px;
        top: -2px;
        right: 2px;
    }

    .toggle-btn.active-toggle span.toggle-lines:before {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .toggle-btn.active-toggle span.toggle-lines:after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    .nav-wrapper {
        position: relative;
        min-height: 160px;
    }

    .nav-wrapper .container {
        position: relative;
    }

    nav.menu {
        padding: 0;
        overflow: scroll;
        position: fixed;
        top: 0;
        background: rgba(0, 0, 0, 0.9);
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9;
        display: flex;
        opacity: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        pointer-events: none;
    }

    .menu a {
        margin: 0 0 10px;
        font-size: 18px;
        color: #fff;
    }

    nav.menu.menu-active {
        pointer-events: all;
        opacity: 1;
    }

    .toggle-btn.active-toggle {
        background: transparent;
        /* background: #222222; */
    }

    nav.menu::after {
        width: 0;
        height: 0;
    }

    .online-consultation-wrapper {
        z-index: 1;
    }

    .online-consultation-main {
        text-align: right;
    }

    .online-consultation-main .boxed-border {
        right: 30px;
        left: inherit
    }

    /* .logo img {
	    width: auto;
	} */
    .chairmans-content {
        margin: 30px 0 0;
    }

    .chairman-image-wrapper {
        margin: 50px 0 0;
    }

    .greetings-wrapper {
        left: 40px;
        right: initial;
    }

    .photo-oftheday-slider-wrapper {
        margin: 50px 0 0;
    }

    .healthtips-wrapper .card>img {
        height: auto;
    }

    .online-consultation-main-wrapper {
        height: auto;
        margin: 20px 0 0;
        min-height: auto;
        padding: 20px;
    }

    .foreign-guests-main::after {
        right: 0;
    }

    .card-body {
        padding: 11px 13px;
    }

    .news-head-section h3 span {
        font-size: 13px;
    }

    .news-head-section h3 {
        font-size: 18px;
    }

    .footer-contact {
        margin: 30px 0 0;
    }

    .footer-menu {
        margin: 30px 0 40px;
    }

    .footer-contact,
    .payments-list {
        padding: 0;
    }

    .footer-contact,
    .payments-list {
        padding: 0;
    }

    .contact-map:before,
    .contact-map:after {
        display: none;
    }

    .inner-banner-caption h1 {
        font-size: 40px
    }

    .inner-banner img {
        width: auto;
        max-width: initial;
        margin: 0 -100%;
    }

    .news-events-wrapper .container {
        max-width: 720px;
    }

    .career-wrapper a.btn.big-btn {
        width: auto;
    }

    .career-wrapper {
        justify-content: flex-start;
        padding: 15px 30px;
        border-radius: 10px;
        margin: 0 15px 40px;
        border: 1px solid rgba(51, 51, 51, 0.2)
    }

    .career-wrapper p {
        line-height: 18px;
    }

    .career-wrapper {
        flex-wrap: wrap;
    }

    .career-wrapper p {
        padding: 0;
        border: none;
        margin: 10px 0;
    }

    .page-name-breadcrumb {
        bottom: 100px;
    }

    .heading-breadcrumb h1 {
        margin: 0 0 10px;
        font-size: 30px;
    }

    .breadcrumb {
        justify-content: flex-start
    }

    .popular-events-list {
        order: 2;
        margin: 20px 0 0
    }

    .card-columns.success-stories-card-columns {
        column-count: 2;
    }

    .testimonial-slider-wrapper {
        margin: 40px 0 0;
    }
}

@media (max-width:767px) {
    header {
        background-size: cover;
        min-height: 160px;
        /* padding: 0 15px; */
    }

    .modal-dialog.video-container {
        margin: 1.5em 1em 3em;
    }

    .banner:after {
        bottom: -160px;
        min-height: 230px;
    }

    .inner-banner {
        height: 400px;
    }

    .inner-banner img {
        margin: 0 -120%;
    }

    section.online-consultation-wrapper {
        margin: -50px 0 0;
    }

    .main-heading {
        font-size: 36px;
    }

    .chairmans-content p {
        font-size: 13px;
        line-height: 22px;
    }

    .newsevents-main {
        flex-wrap: wrap;
    }

    .news-events-head-wrapper,
    .newsevents-slider-wrapper {
        width: 100%;
    }

    .about-img {
        display: none;
    }

    .healthtips-card .card-text {
        font-size: 11px;
        font-weight: 300;
        margin: 10px 0 9px;
    }

    .about-content p {
        font-size: 12px;
        line-height: 20px;
    }

    /* .all-videos-wrapper .video-play-wrapper {
		height: 300px;
	} */
    .footer-mail-main .footer-contact-content h4 a {
        font-size: 12px;
        font-weight: 500;
    }

    .footer-mail-main .footer-contact-content {
        display: inline-flex;
        flex-direction: column;
        order: 2;
        text-align: left;
    }

    .footer-mail-main span {
        border-left: none;
        border-right: 1px solid #b2b2b2;
        margin: 0 15px 0 0;
        padding: 0 15px 0 0;
    }

    .footer-phone-main span i,
    .footer-mail-main span i {
        font-size: 16px;
    }

    .footer-contact-content p a,
    .footer-contact-content h4 a {
        font-size: 14px;
    }

    .footer-contact-area {
        max-width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        padding: 10px 0;
        border-radius: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
    }

    .footer-phone-main,
    .footer-mail-main {
        width: 100%;
        justify-content: center;
    }

    .or-wrapper {
        display: none;
    }

    .footer-mail-main {
        margin: 20px 0 0;
    }

    .footer-contact-content p,
    .footer-contact-content h4 {
        line-height: 16px;
        font-weight: 500;
    }

    .news-events-head-wrapper {
        margin: 0 0 80px;
    }

    .copymenu {
        text-align: left;
    }

    .copymenu {
        margin: 5px 0 0;
    }

    .copymenu>a {
        margin: 0px 10px 0 0;
    }

    .form-head h4,
    .contact-address h4 {
        font-size: 20px
    }

    .contact-address h4 {
        margin: 0 0 15px;
    }

    .service-testimonial-wrapper {
        z-index: 8;
    }

    .inner-banner-caption h1 {
        font-size: 30px
    }

    .footer-head {
        font-size: 16px;
        font-weight: 600;
    }

    .footer-about a,
    .footer-menu ul li a {
        font-size: 12px;
        font-weight: 300
    }

    .footer-menu ul li {
        margin: 0 0 3px;
    }

    .footer-menu {
        margin: 30px 0 10px;
    }

    .developedby {
        text-align: left;
    }

    .copyright h6 {
        font-size: 12px;
        margin: 0 0 6px;
        font-weight: 300;
    }

    .developedby {
        font-size: 12px;
    }

    .copyright {
        margin: 20px 0 0;
    }

    .online-consultation-main-wrapper {
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .video-online-consultation {
        margin: 40px 0 50px;
    }

    .services-list a {
        flex-wrap: wrap;
        padding: 15px;
    }

    .services-contetnt {
        width: 65%
    }

    .services-overlay {
        opacity: 1;
        background: none;
        position: relative;
        width: 100%;
    }

    .services-overlay p {
        color: #333
    }

    .news-events-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }

    .news-events-wrapper .container {
        max-width: 540px;
    }

    .services-detail-wrapper ul li {
        width: 100%;
    }

    .contact-address address {
        flex-wrap: wrap;
        margin: 0 0 6px;
    }

    .contact-address address h5,
    .contact-address address p {
        width: 100%;
    }

    .contact-address address h5 {
        margin: 0 0 6px;
    }

    .contact-address address p {
        width: 100%;
    }

    .gallery-category-name h3 a {
        text-transform: none;
        font-size: 14px;
        font-weight: 400;
    }

    .map-overlay {
        position: relative;
        width: 100%;
        max-width: 100%;
        padding: 15px;
    }

    .map-overlay ul {
        margin: 0;
    }

    .map-overlay ul li {
        color: #fff;
        display: inline-flex;
        padding: 0 20px 0 10px;
        margin: 0;
    }

    .breadcrumb li {
        margin: 0;
    }

    .breadcrumb li,
    .breadcrumb li a {
        font-size: 11px;
    }

    .aboutpage-chairmans-msg-wrapper {
        padding: 0 15px 30px;
        margin-bottom: 10px;
        overflow: hidden;
    }

    .card-columns.success-stories-card-columns {
        column-count: 1;
    }

    .doctor-overlay {
        background: rgba(0, 0, 0, 0.01);
    }

    .doctor-overlay button {
        background: rgba(0, 0, 0, 0.01);
        width: 100%;
        height: 100%;
    }

    .doctor-overlay button i {
        display: none;
    }

    .doctor-wrapper:hover .doctor-overlay {
        opacity: 0;
    }
}

@media (max-width:575px) {
    .inner-banner img {
        margin: 0 -230%;
    }

    .slide__text {
        padding: 15px 0 0;
    }

    .slide__content {
        height: auto;
        top: 70%;
        width: 100%;
        background: none;
    }

    .pages-text {
        justify-content: flex-start;
    }

    .news-events-wrapper .container::after {
        width: 100%
    }

    .nav-wrapper {
        overflow: hidden;
    }

    .online-consultation-area::after {
        border: none;
    }

    .online-consultation-wrapper .container:after {
        right: 0;
    }

    .all-videos-wrapper .video-play-wrapper {
        height: 100%;
    }

    .news-events-head-wrapper {
        margin: 0 0px 80px;
    }

    .newsevents-slider-wrapper {
        padding: 0 0px;
    }

    .foreign-guests-wrapper {
        flex-wrap: wrap;
        max-height: initial;
    }

    .foreign-guests-image, .foreign-guests-contents {
        min-height: initial;
        max-height: initial;
    }

    .foreign-guests-image,
    .foreign-guests-contents {
        width: 1000%;
    }

    .footer-phone-main,
    .footer-mail-main {
        justify-content: flex-start;
        padding: 0 15px;
    }

    .news-events-wrapper .container::before {
        left: 0;
    }

    .testimonial-video-wrapper {
        width: 280px;
        height: 280px;
    }

    .owl-theme .owl-nav button.owl-prev,
    .owl-theme .owl-nav button.owl-next {
        top: 38%
    }

    .video-container iframe {
        height: 300px
    }

    #doctors-popup .modal-body {
        padding: 0;
    }

    .doctor-popup-wrapper {
        flex-wrap: wrap;
    }

    .doctor-popup-wrapper figure,
    .doctor-popup-wrapper article {
        width: 265px;
    }

    .doctor-popup-wrapper article {
        padding: 10px 10px;
    }

    .popular-news-events-card {
        display: flex;
        flex-wrap: wrap;
    }

    .popular-card-image,
    .popular-card-body {
        width: 100%;
    }

    .popular-card-body {
        padding: 10px 0 0;
    }

    .contact-map {
        height: 550px;
    }
}

/* @media (max-width:430px) {}toedit */