.r-tabs .r-tabs-nav {
    margin: 0 0 10px;
    padding: 0 15px;
}

.r-tabs .r-tabs-tab {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.r-tabs .r-tabs-tab::before {
    width: 0;
    height: 0;
}

.r-tabs .r-tabs-tab a {
    border: 1px solid #ddd;
    display: inline-block;
    padding: 8px 15px 7px;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
    color: #666;
    transition: all ease .5s;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    position: relative;
    min-width: 120px;
    text-align: center;
    margin: 0px;
}

.r-tabs .r-tabs-tab:nth-child(1) a {
    border-radius: 40px 0px 0px 40px;
    -webkit-border-radius: 40px 0px 0px 40px;
    -moz-border-radius: 40px 0px 0px 40px;
    -ms-border-radius: 40px 0px 0px 40px;
    -o-border-radius: 40px 0px 0px 40px;
}

.r-tabs .r-tabs-tab:nth-child(2) a {
    border-left: none;
    border-radius: 0px 40px 40px 0;
    -webkit-border-radius: 0px 40px 40px 0;
    -moz-border-radius: 0px 40px 40px 0;
    -ms-border-radius: 0px 40px 40px 0;
    -o-border-radius: 0px 40px 40px 0;
}

.r-tabs li.r-tabs-tab.r-tabs-state-active a, .r-tabs li.r-tabs-tab a:hover {
    background: #803783;
    color: #fff;
    border-color: #803783;
}

.r-tabs li.r-tabs-tab.r-tabs-state-active a::after,
.r-tabs li.r-tabs-tab a:hover::after {
    position: absolute;
    content: "";
    left: 25px;
    bottom: -10px;
    border: 10px solid #803783;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    border-bottom: 0px solid transparent;


}

.r-tabs .r-tabs-panel {
    padding: 15px;
    display: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

/* Accordion responsive breakpoint */
@media only screen and (max-width: 768px) {
    .r-tabs .r-tabs-nav {
        display: block;
    }

    .r-tabs .r-tabs-accordion-title {
        display: none;
    }
}