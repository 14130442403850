/* .modal-dialog {
 opacity: 0;
 top:-100px;
 transition: all 0.9s ease-out;
}

.modal-backdrop {
  opacity:0;
  transition: all 0.9s ease-out;
}

.modal-dialog.show {
  opacity: 1;
  top:0;
}

.modal-backdrop.show {
  opacity: 0.5;
} */

.modal-dialog.show {
  animation: fade 0.3s ease-out;
}

.modal-backdrop.show {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fade {
    0%{
        opacity: 0;
        top:-100px;
    }
    25%{
        opacity: 0.2;
        top:-70px;
    }
    50%{
        opacity: 0.5;
        top:-50px;
    }
    100%{
        opacity: 1;
        top:0;
    }
  /* from {
    opacity: 0;
    top:-100px;
  }
  to {
    opacity: 1;
    top:0px
  } */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}
