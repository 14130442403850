.invalidInput {
  background-color: red;
  color: white;
  text-align: center;
  border-radius: 25px;
  width: 100%;
  height: 50px;
  padding: 0.8rem 0;
  margin: auto;
}
.invalidInput.yellowLabel {
  margin: auto;
  width: 70%;
  background-color: #f2ad00;
}

.invalidInput.greenLabel {
  margin: auto;
  width: 70%;
  background-color: #09a541;
}
