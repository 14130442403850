.invalidInput {
  background-color: none;
  color: red;
  text-align: center;
  border-radius: 25px;
  width: 100%;
  height: auto;
  font-size: 13px;
}
.invalidInput.yellowLabel {
  width: 70%;
  background-color: #f2ad00;
}

.invalidInput.greenLabel {
  width: 70%;
  background-color: #09a541;
}
