* {
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 400;
  color: #333;
}

img {
  object-fit: cover;
}

*,
:hover,
*,
:focus {
  outline-style: none !important;
  text-decoration: none;
}

*:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

::placeholder {
  color: #222222;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #222222;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #222222;
}

.primary-color {
  color: #e73e97;
}

.primary-bg-color {
  background: #e73e97;
}

.form-control:focus {
  border-color: #000 !important;
}

::selection {
  background-color: #00cef7;
  color: rgb(255, 255, 255) !important;
}

a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

p {
  font-size: 13px;
  color: #333;
  line-height: 22px;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 10001;
}

#status {
  width: 50px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url("/resources/img/apple-logo.png");
  background-repeat: no-repeat;
  background-position: center;
  margin: -30px 0 0 -25px;
  z-index: 10001;
  -webkit-background-size: cover;
  background-size: cover;
}

/* Pulse Grow */

@-webkit-keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse-grow {
  to {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.hvr-pulse-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-animation-name: hvr-pulse-grow;
  animation-name: hvr-pulse-grow;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}

.box-shadow {
  border: 1px solid #ddd;
  -webkit-box-shadow: 3px 3px 10px #ddd;
  box-shadow: 3px 3px 10px #ddd;
}

.border {
  border: 1px solid #ddd;
}

.ease1s {
  -webkit-transition: ease 1s;
  -moz-transition: ease 1s;
  -ms-transition: ease 1s;
  -o-transition: ease 1s;
  transition: ease 1s;
}

.ease2s {
  -webkit-transition: ease 2s;
  -moz-transition: ease 2s;
  -ms-transition: ease 2s;
  -o-transition: ease 2s;
  transition: ease 2s;
}

.toggle-btn {
  display: none;
}

header {
  text-align: right;
  position: absolute;
  /* z-index: 2; */
  z-index: 500;
  width: 100%;
  -webkit-background-size: contain;
  background-size: contain;
  min-height: 150px;
  overflow: hidden;
}

.top-strip .container {
  position: relative;
}

.nav-wrapper .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  min-height: 70px;
  padding: 0 70px;
  border-radius: 50px;
  position: relative;
  box-shadow: 0px 10px 18px rgba(51, 51, 51, 0.4);
}

.nav-wrapper .container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  min-height: 80px;
  padding: 0 70px;
  /* border-radius: 50px; */
  position: relative;
  /* box-shadow: 0px 10px 18px rgba(51, 51, 51, 0.4); */
}

@media (max-width: 991px) {
  .nav-wrapper .container-header {
    padding: 0 13px;
  }
}

.nav-wrapper .container2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #fff; */
  background: #803783;
  min-height: 60px;
  padding: 0 70px;
  /* border-radius: 50px; */
  position: relative;
  /* box-shadow: 0px 10px 18px rgba(51, 51, 51, 0.4); */
}

.nav-wrapper .container::after {
  position: absolute;
  content: "";
  right: -30px;
  top: -23px;
  width: 115px;
  height: 115px;
  background: rgba(229, 67, 151, 0.1);
  border-radius: 50%;
  z-index: -1;
}

.logo-div {
  width: 290px !important;
  /* height: 59px !important; */
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: -18px;
}

@media (max-width: 480px) {
  .logo-div {
    width: 135px !important;
    margin-top: 0;
    margin-left: 0;
  }
}

.logo {
  /* position: absolute;
left: 0;
top: 0; */
  /* width: 97px !important;
  height: 45px !important; */

  /* width: auto;
  height: auto; */
  width: 100%;
  height: auto;
  padding: 0;
  z-index: 8;
  position: relative;
}

/* .logo:before {
  position: absolute;
  content: "";
  left: -40px;
  top: -37px;
  width: 115px;
  height: 115px;
  background: rgba(229, 67, 151, 0.1);
  border-radius: 50%;
  z-index: -1;
} */

.logo img {
  width: 120px;
}

@media (max-width: 575px) {
  .logo img {
    width: 112px;
  }
}

.top-strip {
  padding: 0;
}

nav.menu {
  position: relative;
  padding: 0 45px 0 0;
}

/* nav.menu::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: url("/resources/img/sabinehospital-icons/sabinehospital-heartbeat.svg")
    0 100% no-repeat;
  background-size: contain;
  width: 34px;
  height: 24px;
} */

.call-mail a {
  color: #333;
  font-size: 14px;
  font-weight: 400;
}

.phone {
  font-size: 12px;
}

.mail {
  margin: 0 0 0 30px;
}

.call-mail img {
  max-width: 21px;
}

.nav-wrapper {
  padding: 0px 0 11px;
}

nav {
  -webkit-transition: ease 1s;
  -o-transition: ease 1s;
  transition: ease 1s;
}

.menu a {
  color: #fff;
  text-transform: uppercase;
  font-size: 15px;
  margin: 0 16px 0 8px;
  font-weight: 500;
  position: relative;
  -webkit-transition: ease 1s;
  -o-transition: ease 1s;
  transition: ease 1s;
}

.menu a:hover,
.menu a.active {
  color: #fff;
  /* color: #e54397; */
}

/* .menu a.active::before,
.menu a.active::after {
  position: absolute;
  content: "";
  top: -3px;
  background: #fff;
  height: 28px;
  width: 1px;
  transform: rotate(10deg);
} */

.menu a.active::before {
  left: -5px;
}

.menu a.active::after {
  right: -5px;
}

@media (max-width: 991px) {
  .menu a:hover,
  .menu a.active {
    color: #803783;
  }

  .menu a.active::before,
  .menu a.active::after {
    background: #803783;
  }
}

.menu a.cart-button {
  background: #32a110;
  color: #fff;
  padding: 7px 20px;
  border-radius: 30px;
  font-size: 13px;
}

.menu a.cart-button img {
  width: 17px;
  margin: -2px 2px 0 0;
}

.menu a.search-icon {
  background: #32a110;
  display: inline-flex;
  /*width: 31px;
height: 31px;*/
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
  top: 3px;
}

.menu a.search-icon img {
  width: 35px;
  padding: 10px;
}

span.search-form {
  display: none;
  position: absolute;
  top: 40px;
  right: 0;
}

.search-form input {
  max-width: 250px;
  width: 250px;
  padding: 7px 10px;
}

span.search-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background: #31a110;
  border: none;
  width: 40px;
}

.banner {
  position: relative;
  overflow: hidden;
}

.banner:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -350px;
  right: 0;
  width: 200%;
  min-height: 550px;
  z-index: 1;
  background-size: cover;
  pointer-events: none;
  background: #fff;
  transform: rotate(-4deg);
}

.banner img {
  width: 100%;
}

.carousel__dot-group {
  position: relative;
  z-index: 9;
  background: #f00;
  text-align: center;
}

.carousel__dot-group button {
  background: #ff0;
  width: 30px;
  height: 30px;
  margin: 0 10px;
  border-radius: 30px;
}

.carousel-inner {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.carousel-item {
  min-height: 600px;
}

.carousel-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.banner-anim-thumb-default span {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 30px !important;
  background: no-repeat !important;
  box-shadow: none !important;
  border: 2px solid #803783;
  position: relative;
}

.banner-anim-thumb-default span.active::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 4px;
  width: 20px !important;
  height: 20px !important;
  background: #803783;
  border-radius: 50%;
  transition: all ease 1s;
}

.banner-content {
  min-height: 600px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.banner-content h2 {
  font-size: 70px;
  color: #fff;
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  margin: 0;
}

.banner-content p {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
}

.banner-more a {
  background: #fff;
  display: inline-flex;
  max-width: 150px;
  min-height: 45px;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  text-transform: uppercase;
  color: #113afa;
}

.banner-more img {
  width: 7px;
  margin: 1px 0 0 10px;
}

.banner-img {
  display: flex;
  align-items: center;
  height: 100%;
}

.banner-img img {
  margin: 0 0 0 10px;
}

.carousel-indicators {
  z-index: 5;
}

.online-consultation-wrapper {
  margin: -180px 0 0;
  position: relative;
  z-index: 1;
}

.online-consultation-wrapper .container {
  position: relative;
}

.online-consultation-wrapper .container:after {
  background: rgba(229, 67, 159, 0.1);
  border-radius: 50%;
  position: absolute;
  content: "";
  width: 220px;
  height: 220px;
  right: -55px;
  top: -37px;
  pointer-events: none;
  z-index: -1;
}

.online-consultation-main {
  position: relative;
}

.boxed-border {
  border: 15px solid;
  border-image-source: linear-gradient(
    0deg,
    rgba(255, 161, 210, 1) 0%,
    rgba(255, 17, 140, 1) 100%
  );
  border-image-slice: 1;
  width: 126px;
  height: 126px;
  position: absolute;
  top: -60px;
  left: -30px;
}

.online-consultation-area {
  background: #fff;
  padding: 0 10px 0 22px;
  position: relative;
  box-shadow: 0px 5px 15px #ccc;
  display: inline-flex;
  min-height: 90px;
  justify-content: center;
  flex-direction: column;
}

.online-consultation-area::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  height: 100%;
  background: rgb(255, 161, 210);
  background: linear-gradient(
    0deg,
    rgba(255, 161, 210, 1) 0%,
    rgba(255, 17, 140, 1) 100%
  );
}

.online-consultation-area::after {
  position: absolute;
  content: "";
  right: -45px;
  top: 0;
  bottom: 0;
  border: 45px solid #fff;
  border-right: 0 solid transparent;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}

.online-consultation-area p,
.online-consultation-area h6 {
  margin: 0;
}

.online-consultation-area p {
  font-size: 15px;
  color: #804c9c;
  margin: 0 0 3px;
}

.online-consultation-btn {
  background: #803783;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  padding: 10px 25px;
  display: inline-block;
  border-radius: 30px;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
}

.online-consultation-btn:hover,
.online-consultation-btn:focus {
  background: #800649;
  color: #ffffff;
}

.header-btn {
  /* background: #e54397; */
  background: #804c9c;
  border: none;
  color: #fff;
  font-weight: 500;
  /* width: 205px !important; */
  font-size: 14px;
  padding: 10px 40px;
  display: inline-block;
  border-radius: 40px;
  margin-left: 12px;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
}

.header-btn:hover,
.header-btn:focus {
  background: #800649;
  color: #ffffff;
}

@media (max-width: 991px) {
  .header-btn {
    display: none;
  }
}

.footer-btn {
  background: #803783;
  border: none;
  color: #fff;
  font-weight: 500;
  width: 205px !important;
  font-size: 15px;
  padding: 8px 20px;
  display: inline-block;
  border-radius: 30px;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
}

.footer-btn:hover,
.footer-btn:focus {
  background: #800649;
  color: #ffffff;
}

.doctors-list {
  margin: 0 0 30px;
}

.doctor-wrapper {
  background: #f7f7f7;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.35);
  border: none;
  position: relative;
  margin: 0;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 605.945px;
  height: 207.794px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 8px;
}

.doctor-wrapper:hover {
  border: 1px solid #803783;
}

.doc-wrapper {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

@media (max-width: 575px) {
  .doctor-wrapper {
    /* width: 375px; */
    width: 100%;
    height: auto;
    padding-bottom: 5px;
  }

  .doctor-wrapper:hover {
    border: 1px solid #803783;
  }
}

@media (max-width: 900px) {
  .doctor-wrapper {
    /* width: 375px; */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

.doctor-wrapper figure {
  margin: 0;
}

.doc-image {
  /* min-height: 200px;
  max-height: 200px;
  max-width: 200px;
  min-width: 200px; */
  width: 164.925px;
  height: 174.107px;
  border-radius: 8px;
}

@media (max-width: 575px) {
  .doc-image {
    width: 219px;
    height: 231px;
  }
}

.doctor-wrapper article {
  padding: 5px 10px 5px 15px;
  position: relative;
  margin: 10px 0 0;
}

#doctor-name-field {
  height: 50px;
}

/* .doctor-wrapper article::before {
position: absolute;
content: "";
left: 7px;
top: 5px;
background: #ff118c;
height: 30px;
width: 2px;
} */

.doctor-wrapper article h6,
.doctor-wrapper article p {
  margin: 0;
}

.doctor-wrapper article h6 {
  color: #804c9c;
  position: relative;
  margin: 0 0 7px;
}

.doctor-wrapper article h6:before {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -5px;
  background: #804c9c;
  height: 2px;
  width: 40px;
  margin-left: -25px;
}

.doctor-overlay {
  padding: 1em;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(250, 231, 244, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
}

.doctor-overlay p {
  color: #333;
}

.doctor-overlay button {
  background: #fff;
  padding: 7px 10px 5px;
}

.doctor-wrapper:hover .doctor-overlay {
  opacity: 1;
}

.doctors-head {
  margin-bottom: 20px;
  /* border: 1px solid #ddd; */
  padding: 8px 15px 9px;
  /* border-top: 1px solid #ddd; */
  text-align: center;
  font-weight: 600;
  background: #f7f7f7;
  border-radius: 6px;
  border: 1px rgba(119, 118, 118, 0.5) solid;
  color: #050505;
  font-size: 20px;
  font-family: Roboto;
  font-weight: 700;
  /* box-shadow: 0px 5px 10px #ddd; */
}

#doctorsButton {
  transition: all 0.3s;
}

#doctorsButtonDoc {
  transition: all 0.3s;
  width: 203px !important;
  height: 41px !important;
}

#doctorsButtonDoc:hover {
  background-color: white;
  color: #e34597;
  border: 1px solid #e34597;
}

#doctorsButton:hover {
  background-color: white;
  color: #e34597;
  border: 1px solid #e34597;
}

.modal.show .modal-dialog {
  -webkit-transform: none;
  position: relative;
  top: 50% !important;
  transform: translate(4%, -50%) !important;
}

@media (max-width: 575px) {
  .modal.show .modal-dialog {
    -webkit-transform: none;
    position: relative;
    top: 50% !important;
    transform: translateY(-50%) !important;
  }
}

@media (min-width: 250px) {
  .doctors-list {
    margin-bottom: 33px !important;
  }

  .doctors-head {
    border-radius: 10px;
    margin-bottom: 21px;
    border: 1px solid #ddd;
    padding: 8px 15px 9px;
    /* border-top: 1px solid #ddd; */
    background: #fff;
    width: 1240px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    color: #252023;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 700;
    height: 63px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    background: #f7f7f7;

    /* box-shadow: 0px 5px 10px #ddd; */
  }
}

@media (max-width: 575px) {
  .doctors-head {
    width: 100% !important;
    margin-bottom: 20px !important;
    justify-content: center;
    padding-left: 0 !important;
    height: 63px;
    font-size: 16px;
  }

  .doctors-list {
    margin-bottom: 13px !important;
  }
}

.modal {
  overflow: scroll;
}

#doctors-popup .modal-body {
  padding: 0px;
}

.doctor-popup-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 15px;
  width: 750px;
  background: #f7f7f7;
  padding: 21px;
  border-radius: 8px;
}

@media (max-width: 575px) {
  .doctor-popup-wrapper {
    width: 285px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: none;
    padding: 0;
    border-radius: 0;
  }

  .doctor-figure {
    padding: 27px;
    background: #f7f7f7;
    border-radius: 5px;
    width: 285px !important;
  }

  .doctor-detail-image {
    height: 231px !important;
    width: 231px !important;
  }
}

.doctor-popup-wrapper figure {
  width: 265px;
  margin: 0 20px 0 0;
  /* border-right: 1px solid #e4e0e0; */
}

@media (max-width: 575px) {
  .doctor-popup-wrapper figure {
    margin: 0;
    width: 262px;
  }
}

.doctor-popup-wrapper article {
  width: 60%;
  padding: 0 10px;
}

#doctors-popup article p {
  margin: 0 0 3px;
  line-height: 20px;
}

.section-title h2,
.section-title h3,
.section-title h4,
.section-title h5,
.section-title h6 {
  color: #804c9c;
  display: flex;
  align-items: center;
  justify-content: start;
}

.section-title img {
  width: 40px;
  margin: 0 10px 0 0;
}

.section-title p {
  max-width: 700px;
  line-height: 20px;
  width: 100%;
}

.service-testimonial-wrapper {
  margin: 60px 0 0;
  position: relative;
  z-index: 4;
  overflow: hidden;
  padding: 0px 0 25px;
}

.services-list-wrapper {
  margin: 30px 0 0;
}

.services-list {
  width: 80%;
}

@media (max-width: 575px) {
  .services-list {
    width: 85%;
    margin-left: 7%;
    /* position: relative !important;
    left: 50% ;
    transform: translateX(-50%) ;
    animation-name: fadeInUp !important; */
  }
}

.services-list a {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #fff;
  border: 1px solid #edecec;
  border-radius: 10px;
  min-height: 60px;
  margin: 0 0 40px;
  padding: 0 15px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  box-shadow: 3px 7px 10px #ddd;
  flex-wrap: wrap;
}

.services-list::before {
  position: absolute;
  content: "";
  left: -3px;
  top: -15px;
  background: rgba(229, 67, 159, 0.1);
  border-radius: 50%;
  width: 90px;
  height: 90px;
  z-index: -1;
}

.services-overlay {
  position: absolute;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  opacity: 0;
  background: rgba(229, 67, 151, 0.9);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
  display: flex;
  align-items: center;
}

.services-overlay p {
  margin: 0;
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  padding: 8px;
}

.services-list:hover .services-overlay {
  opacity: 1;
}

.services-icon {
  width: 50px;
  margin: 0 7px 0 0;
}

.services-icon img {
  width: auto !important;
  max-width: 55px;
}

.services-icon img.small-icon {
  max-width: 45px;
}

.services-contetnt {
  border-left: 2px solid #6e6a6a;
  padding-left: 7px;
  width: 71%;
}

.services-contetnt h5 {
  margin: 0;
  font-size: 14px;
  color: #4f4b4b;
}

.testimonial-slider-wrapper {
  position: relative;
}

.testimonial-slider-wrapper::after {
  position: absolute;
  content: "";
  right: -410px;
  top: -370px;
  background: #fafafa;
  width: 85%;
  height: 1500px;
  border-radius: 500px;
  z-index: -1;
  transform: rotate(-70deg);
  -webkit-transform: rotate(-70deg);
  -moz-transform: rotate(-70deg);
  -ms-transform: rotate(-70deg);
  -o-transform: rotate(-70deg);
}

.testimonial-slider-wrapper .section-title h2 {
  justify-content: center;
}

.testimonials-slider {
  margin: 20px 0 0;
}

.testimonial-content-wrapper {
  text-align: center;
  padding: 0 50px;
}

.testimonial-avatar-image {
  width: 110px;
  height: 110px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 5px 10px #ddd;
}

.testimonial-avatar-image img {
  width: 100%;
  height: 100%;
}

.testimonial-contetns-wrapper {
  margin: 10px 0 0;
  /* max-height: 4rem;
    margin: 10px 0 0;
    overflow: hidden;
    text-overflow: ellipsis; */
}

/* .testimonial-contetns-wrapper::before{
    position: absolute;
    content:"..."

} */

.testimonial-contetns-wrapper p {
  font-size: 13px;
  font-weight: 400;
  color: #4f4b4b;
}

.testimonial-video-wrapper {
  position: relative;
  width: 350px;
  overflow: hidden;
  height: 350px;
  border-radius: 50%;
  margin: 0 auto;
}

.testimonials-slider.owl-theme .owl-nav .owl-prev {
  left: -21px;
}

.testimonials-slider.owl-theme .owl-nav .owl-next {
  right: 0;
}

.testimonials-slider.owl-theme .owl-nav .owl-prev span,
.testimonials-slider.owl-theme .owl-nav .owl-next span {
  top: -3px;
}

.video-play-wrapper {
  width: 350px;
  height: 350px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}

.video-play-overlay {
  background: rgba(229, 67, 151, 0.3);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
}

.videos {
  display: none;
}

.photo-oftheday-slider-img {
  min-height: 350px;
}

.owl-carousel .owl-item .photo-oftheday-slider-img img {
  height: 100%;
  min-height: 340px;
  max-height: 340px;
}

.photo-oftheday-slider-img img {
  /* height: 350px; */
  width: 100%;
}

/* BOF Chairmans Msg Wrapper */

.chairmans-msg-wrapper {
  position: relative;
  margin: 50px auto 0;
}

.chairmans-msg-wrapper::after {
  position: absolute;
  content: "";
  left: 65%;
  bottom: -20px;
  width: 600px;
  height: 300px;
  background: url("/resources/img/section-bg.png") center center no-repeat;
  background-size: contain;
  z-index: -1;
}

.chairmans-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  position: relative;
}

.chairmans-content::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  background: rgba(229, 67, 151, 0.1);
  width: 250px;
  height: 250px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  pointer-events: none;
  left: -120px;
  top: 60px;
}

.main-heading {
  font-family: "Great Vibes", cursive;
  font-size: 50px;
  color: #803783;
  font-weight: 400;
}

.main-heading img {
  width: 40px;
  margin: -6px 8px 0 0;
}

.chairmans-content p {
  font-size: 15px;
  font-weight: 300;
  line-height: 28px;
  color: #000;
}

.chairman-image-wrapper {
  position: relative;
}

.chairman-image {
  position: relative;
  display: inline-block;
}

.chairman-image:before {
  position: absolute;
  content: "";
  background: #f8f7f7;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: -20px;
  top: -20px;
}

.greetings-wrapper {
  position: absolute;
  bottom: 35px;
  right: 0;
  /* background: #e54397; */
  background: rgba(0, 108, 130, 0.9);
  padding: 10px 25px;
}

.greetings-wrapper::after {
  position: absolute;
  content: "";
  right: -10px;
  top: 0;
  bottom: 0;
  background: #803783;
  height: 100%;
  width: 5px;
}

.greetings-wrapper p {
  margin: 0;
  color: #fff;
}

.greetings-wrapper h4 {
  margin: 0;
  color: #fff;
}

/* EOF Chairmans Msg Wrapper */

/* BOF News & Events Section */

.news-events-wrapper {
  padding: 100px 0 0px;
}

.news-events-wrapper .container {
  max-width: 1400px;
  position: relative;
}

.news-events-wrapper .container::before {
  position: absolute;
  content: "";
  left: 160px;
  top: -100px;
  width: 211px;
  height: 211px;
  background: rgba(229, 67, 151, 0.1);
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.news-events-wrapper .container::after {
  position: absolute;
  content: "";
  left: -50px;
  top: 50%;
  width: 600px;
  height: 300px;
  background: url("/resources/img/section-bg.png") center center no-repeat;
  background-size: contain;
  z-index: -1;
}

.newsevents-main {
  flex-wrap: nowrap;
}

.news-events-head-wrapper {
  width: 19%;
  margin-right: 20px;
}

.newshead-wrapper {
  background: #fff;
  box-shadow: 0px 0px 10px #ccc;
  text-align: center;
  padding: 35px 0;
  height: 100%;
}

.news-icon {
  background: #f8f7f7;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 16px;
  display: inline-block;
}

.news-head-section h3 {
  text-align: center;
  margin: 13px 0 0;
}

.news-head-section h3 span {
  display: block;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 500;
}

.secparator-icon img {
  width: 28px;
}

.news-head-section p {
  font-size: 12px;
  color: #736363;
  padding: 0 30px;
  line-height: 18px;
}

.btn {
  font-weight: 500;
  background: #803783;
  color: #fff;
  padding: 0 26px;
  min-height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  font-size: 14px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}

.btn i {
  color: #fff;
  margin-left: 10px;
}

.btn:hover,
.btn:focus {
  background: #800649 !important;
  color: #ffffff;
}

.small-btn {
  font-size: 11px;
  font-weight: 500;
  padding: 0 12px;
  min-height: 28px;
}

.small-btn i {
  margin: 0px 0 0 5px;
}

.newsevents-slider-wrapper {
  flex: 1;
  width: 80%;
}

.news-events-card {
  width: 85%;
  border-radius: 0;
  box-shadow: 0px 0px 7px #ccc;
  /* height:100%; */
}

@media (max-width: 575px) {
  .news-events-card {
    width: 100% !important;
  }

  .chairmans-msg-wrapper {
    margin: 30px auto 0;
  }
}

/* @media (max-width: 575px){
  .news-events-card {
    width: 360px !important;
  }

} */

.news-events-card img.card-img-top {
  display: block;
  width: 100%;
  max-height: 180px;
  max-width: 100%;
  min-height: 180px;
}

.card-body {
  padding: 20px 15px;
}

.news-events-card .card-body {
  position: relative;
  padding-bottom: 45px;
}

.news-events-card .btn-wrapper {
  position: absolute;
  bottom: 15px;
}

.card-image {
  position: relative;
}

.date-wrapper {
  position: absolute;
  bottom: 10px;
  right: 0;
  /* background: rgba(229, 67, 151, 0.8); */
  /* background: rgba(0, 163, 196, 0.8); */
  background: #8061B6CC;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  padding: 2px 13px;
  border-radius: 12px 0px 0px 12px;
}

.card-title {
  margin-bottom: 0.75rem;
  border-left: 3px solid #333;
  padding: 0px 0 0 6px;
  margin: 0 0 10px;
}

.card-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: #000;
}

.nav-right-top .owl-nav {
  position: absolute;
  right: 0;
  top: -55px;
  margin: 0;
}

/* EOF News & Events Section */

/* BOF Guests Photo Of The Day */

.guests-photoofthday-wrapper {
  padding: 0;
}

.foreign-guests-main {
  height: 100%;
  padding: 0 0;
  position: relative;
}

/* .foreign-guests-main::after {
  position: absolute;
  content: "";
  right: -200px;
  top: -200px;
  width: 373px;
  height: 373px;
  background: rgba(229, 67, 151, 0.1);
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
} */

.foreign-guests-wrapper {
  background: #fff;
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 100%;
  max-height: 360px;
}

.foreign-guests-image {
  width: 50%;
  min-height: 350px;
  background-size: cover !important;
  position: relative;
}

.foreign-guests-image::after {
  position: absolute;
  content: "";
  border: 10px solid #fff;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  top: 46px;
  right: 0;
}

.foreign-guests-contents {
  width: 50%;
  flex: unset;
  padding: 25px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  min-height: 350px;
}

.photo-oftheday-slider {
  background: #fff;
  box-shadow: 3px 3px 10px #ddd;
  padding: 5px;
  max-height: 350px;
}

.owl-theme.photo-oftheday-slider.nav-right-top .owl-nav {
  top: -37px;
}

.owl-theme.photo-oftheday-slider .owl-nav .owl-prev {
  left: -70px;
}

.owl-theme.photo-oftheday-slider .owl-nav .owl-next {
  right: 0;
}

.owl-theme.photo-oftheday-slider .owl-nav .owl-prev,
.owl-theme.photo-oftheday-slider .owl-nav .owl-next {
  width: 30px;
  height: 30px;
}

.owl-theme.photo-oftheday-slider .owl-nav .owl-prev span,
.owl-theme.photo-oftheday-slider .owl-nav .owl-next span {
  font-size: 32px;
  top: 0;
}

/* EOF Guests Photo Of The Day */

/* BOF Healthtips-About Us */

.healthtips-aboutus-wrappper {
  position: relative;
  overflow: hidden;
  padding-top: 50px;
}

.healthtips-aboutus-wrappper::after {
  position: absolute;
  content: "";
  right: -60px;
  top: 0px;
  width: 370px;
  height: 370px;
  background: rgba(229, 67, 151, 0.1);
  z-index: -1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.card,
.card-img-top {
  border-radius: 0;
}

.healthtips-card .card-title {
  border-color: #803783;
}

.healthtips-slider.owl-theme .owl-nav .owl-next,
.newsevents-slider.owl-theme .owl-nav .owl-next {
  right: 0;
}

.healthtips-slider.owl-theme .owl-nav .owl-prev,
.newsevents-slider.owl-theme .owl-nav .owl-prev {
  left: -90px;
}

/* BOF Healthtips-About Us */

/* BOF Owl Nav Middle */

.owl-theme .owl-nav .owl-prev,
.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  color: #9b9799;
  margin: 0;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  margin: 0 0 0 2px;
  box-shadow: 0px 0px 10px #ddd;
}

.owl-theme .owl-nav .owl-prev {
  left: -20px;
}

.owl-theme .owl-nav .owl-next {
  right: -10px;
}

.total-outer-wrap .owl-theme .owl-nav .owl-next {
  right: -20px !important;
}

.video-slider-wrapper .owl-theme .owl-nav .owl-next {
  right: -20px !important;
}

.owl-theme .owl-nav .owl-prev span,
.owl-theme .owl-nav .owl-next span {
  font-size: 44px;
  line-height: 24px;
  font-weight: 300;
  position: relative;
  top: 0;
}

.owl-theme .owl-nav .owl-prev:hover,
.owl-theme .owl-nav .owl-next:hover {
  background: #803783;
  color: #fff;
}

.owl-theme .owl-nav .owl-prev:hover span,
.owl-theme .owl-nav .owl-next:hover span {
  color: #fff;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.9;
}

/* EOF Owl Nav Middle */

/* BOF About Section */

.about-img-wrapper {
  border-radius: 0;
  overflow: hidden;
  height: 100%;
  padding: 42px 0 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.about-img {
  background: #fff;
  box-shadow: 3px 3px 10px #ddd;
  padding: 5px;
  height: 100%;
}

.about-img img {
  height: 100%;
  width: 100%;
}

.about-content-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.about-content p {
  font-size: 13px;
  line-height: 22px;
}

/* BOF About Section */

.video-online-consultation {
  margin: 80px 0 90px;
  position: relative;
}

.video-online-consultation::before {
  position: absolute;
  content: "";
  left: -210px;
  top: -220px;
  width: 370px;
  height: 370px;
  background: rgba(229, 67, 151, 0.1);
  border-radius: 50%;
  z-index: -1;
}

.modal-dialog.video-container {
  max-width: 600px;
}

.modal-dialog.video-container-booking {
  max-width: 1000px;
}

.video-container .modal-body {
  padding: 10px 10px 4px;
  border-radius: 0;
}

.video-container-booking .modal-body {
  padding: 10px 10px 4px;
  border-radius: 0;
}

.modal-dialog.video-container button.close {
  background: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -20px;
  position: absolute;
  right: -20px;
  opacity: 1;
  color: #803783;
}

.modal-dialog.video-container-booking button.close {
  background: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: -20px;
  position: absolute;
  right: -20px;
  opacity: 1;
  color: red;
}

.modal-dialog.video-container-booking button.close:hover {
  opacity: 1 !important;
}

.modal-dialog.video-container button.close:hover {
  opacity: 1 !important;
}

/* BOF Medical Tourism Section CSS */

.artcle-wrapper {
  background: #fff;
  padding: 6px 7px 15px;
}

/* new */
.article-wrapper figure {
  width: 100%;
  position: relative;
}

.artcle-wrapper img {
  width: 100%;
}

/* EOF Medical Tourism Section CSS */

.all-videos-wrapper {
  position: relative;
}

.all-videos-wrapper .video-play-wrapper {
  position: relative;
  border-radius: 0;
  width: 100%;
}

.all-videos-wrapper .video-play-wrapper > img {
  height: 100%;
}

.all-videos-wrapper:hover {
  cursor: pointer;
}

.online-consultation-main-wrapper {
  display: flex;
  align-items: center;
  min-height: 350px;
  background: #fff;
  box-shadow: 0 0 10px #ddd;
  padding: 0 25px;
  border-radius: 10px;
}

.online-consultation-main-wrapper .boxed-border {
  z-index: -1;
  right: -30px;
  left: inherit;
  top: -50px;
}

.online-consultation-main-wrapper p {
  font-size: 13px;
  color: #333;
  line-height: 22px;
}

.footer-contact-wrapper {
  margin: 0 0 -50px;
  position: relative;
  z-index: 2;
}

.footer-contact-area {
  max-width: 298px;
  /* margin: 0 auto; */
  min-height: 100px;
  background: #fff;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 36px;
  border: 1px solid #ddd;
  position: relative;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}

.footer-contact-area::after {
  position: absolute;
  content: "";
  left: -40px;
  top: -20px;
  width: 143px;
  height: 143px;
  background: rgba(229, 67, 151, 0.1);
  border-radius: 50%;
  z-index: -1;
}

.footer-email-area {
  max-width: 482px;
  /* margin: 0 auto; */
  min-height: 100px;
  background: #fff;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 36px;
  border: 1px solid #ddd;
  position: relative;
  -webkit-border-radius: 32px;
  -moz-border-radius: 32px;
  -ms-border-radius: 32px;
  -o-border-radius: 32px;
}

.footer-email-area::after {
  position: absolute;
  content: "";
  left: -40px;
  top: -20px;
  width: 143px;
  height: 143px;
  background: rgba(229, 67, 151, 0.1);
  border-radius: 50%;
  z-index: -1;
}

.footer-phone-email {
  display: flex;
  justify-content: center;
  gap: 28px;
}

.footer-phone-main,
.footer-mail-main {
  display: flex;
  align-items: center;
}

.footer-phone-main span {
  border-right: 1px solid #b2b2b2;
  padding-right: 15px;
  margin-right: 15px;
}

.footer-mail-main span {
  border-right: 1px solid #b2b2b2;
  padding-right: 15px;
  margin-right: 15px;
}

.footer-mail-main img {
  width: 53px;
  height: 53px;
}

.footer-phone-main span i,
.footer-mail-main span i {
  color: #e54397;
  font-size: 60px;
}

.footer-phone-main img {
  width: 36px;
  height: 36px;
}

.footer-contact-content p,
.footer-contact-content h4 {
  margin: 0;
  text-align: left;
  color: #494444;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
}

.footer-contact-content h4 {
  color: #494444 !important;
  font-size: 20px !important;
  font-family: Poppins !important;
  font-weight: 700 !important;
}

.footer-contact-content p a,
.footer-contact-content h4 a {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #000 !important;
}

.footer-mail-main {
  text-align: right;
}

.footer-mail-main .footer-contact-content h4 a {
  font-size: 16px;
}

footer {
  background: #333333;
  padding: 100px 0 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

footer:after {
  position: absolute;
  content: "";
  left: -50px;
  top: -100px;
  background: #373737;
  width: 590px;
  height: 500px;
  border-radius: 200px;
  z-index: 0;
  transform: rotate(45deg);
}

footer .container,
footer .copyright {
  position: relative;
  z-index: 9;
}

.footer-head {
  font-size: 21px;
  color: #fff;
  font-weight: 700;
  text-transform: none;
  padding-left: 10px;
  margin: 0 0 20px;
}

.footer-about a {
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  display: inline-block;
  margin: 0 5px 5px 0;
  border: 1px solid #ccc;
  padding: 4px 10px;
}

.footer-about .no-link {
  font-size: 13px;
  color: transparent;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.54), transparent);
  display: inline-block;
  margin: 0 5px 5px 0;
  border: 1px solid #ccc;
  padding: 4px 10px;
}

.footer-social {
  margin: 20px 0 0;
  border-top: 1px solid #535657;
  padding: 8px 0 0;
}

.footer-social > a:hover {
  background: #fff;
}

.footer-social a img {
  width: 20px;
  opacity: 0.3;
  -webkit-transition: ease 1s;
  -o-transition: ease 1s;
  transition: ease 1s;
}

.footer-social a img:hover {
  opacity: 1;
}

footer h2,
footer h3,
footer h4 {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 500;
  color: #d2cece;
}

.footer-menu ul {
  margin: 0;
  padding: 0;
}

.footer-menu ul li {
  list-style: none;
  margin: 0 0 7px;
  position: relative;
  padding: 0 0 0 15px;
  width: 48%;
  display: inline-block;
}

.footer-menu ul li:before {
  position: absolute;
  content: "" !important;
  left: 0;
  top: 10px;
  width: 3px;
  height: 4px;
  background: #bbbbbb;
}

.footer-menu ul li a {
  color: #fff;
  font-size: 14px;
  text-transform: none;
  font-weight: 400;
}

.footer-menu ul li a:hover {
  color: #bdbebe;
}

.footer-contact address {
  display: flex;
  flex-grow: 1;
  margin: 0 0 12px;
}

.footer-contact p {
  width: 70%;
  color: #fff;
  font-size: 13px;
  line-height: 22px;
}

.footer-contact address h5 {
  font-weight: 600;
  font-size: 13px;
  color: #d2cece;
  width: 50px;
}

footer h5 img {
  width: 37px;
  margin: 0 4px 0 0;
}

.footer-social > a {
  margin: 0 5px 0 0;
  background: #aaa;
  color: #333333;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.footer-social a i {
  color: #333333;
}

.copyright {
  background: #2d2b2b;
  padding: 20px 0;
  margin: 50px 0 0;
}

.copyright h6 {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 26px;
  color: white;
}

.copyright a {
  color: #fff;
  font-weight: 600;
}

.copyright a:hover {
  color: #b1b8ba;
}

.developedby {
  text-align: right;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.inner-wrapper {
  margin: 80px 0;
}

.inner-pages-wrapper section.online-consultation-wrapper {
  margin: 0;
}

/* .inner-page-area {
  padding: 60px 0 50px;
  margin-top: 0;
} */

.inner-page-area {
  padding: 60px 0 50px;
  margin-top: 0;
}

@media (max-width: 575px) {
  .inner-page-area {
    padding: 0px 0 50px;
    margin-top: 0;
  }
}

/* BOF About Us Page CSS */

.inner-banner {
  height: 400px;
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.inner-banner:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  width: 200%;
  min-height: 80px;
  z-index: 0;
  background-size: cover;
  pointer-events: none;
  background: #fff;
  transform: rotate(-2deg);
}

.inner-banner img {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
}

.page-name-breadcrumb {
  position: absolute;
  bottom: 50px;
  width: 100%;
  z-index: 1;
}

.page-name-breadcrumb:after {
  position: absolute;
  content: "";
  left: 0;
  top: -40px;
  right: 0;
  width: 100%;
  height: 200px;
  /*Permalink-usetoeditandsharethisgradient:;
https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100;
White+to+Transparent*/
  background: -moz-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /*FF3.6-15*/
  background: -webkit-linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /*Chrome10-25,Safari5.1-6*/
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /*W3C,IE10+,FF16+,Chrome26+,Opera12+,Safari7+filter:;*/
  /* progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); */
  /* IE6-9 fallback on horizontal gradient */
  /* -webkit-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
z-index: 0; */
}

.heading-breadcrumb h1 {
  text-transform: uppercase;
  z-index: 2;
  color: #333;
  position: relative;
  margin: 0 0 15px;
}

.heading-breadcrumb h1:after {
  position: absolute;
  content: "";
  left: 0;
  width: 60px;
  height: 1px;
  background: #333;
  margin-left: -10px;
  bottom: 0px;
}

@media (max-width: 575px) {
  .heading-breadcrumb h1:after {
    margin-left: 0px;
  }
}

.heading-breadcrumb {
  position: relative;
  bottom: 0;
  z-index: 3;
}

.breadcrumb {
  margin: 0;
}

.breadcrumb {
  margin: 0;
  background: no-repeat;
  padding: 0;
  justify-content: flex-end;
}

.breadcrumb li {
  margin: 0 5px;
}

.breadcrumb li,
.breadcrumb li a {
  font-weight: 500;
  color: #333;
  font-size: 13px;
  text-transform: uppercase;
}

.aboutpage-contetns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.aboutpage-contetns h2 {
  margin: 0;
}

.aboutpage-contetns p {
  font-size: 13px;
  line-height: 23px;
}

.vision-mission-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.vision-mission {
  background: #fff;
  box-shadow: 0px 5px 10px #ddd;
  position: relative;
  z-index: 2;
  padding: 3px 15px 10px;
  margin: 0 0 10px;
}

.vision-mission h4 {
  font-size: 16px;
  margin: 10px 0;
  position: relative;
}

.vision-mission h4::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  background: #333;
  height: 2px;
  width: 15px;
}

.vision-mission p {
  line-height: 18px;
  margin: 0;
}

.aboutpage-chairmans-msg-wrapper {
  padding: 100px 0 0;
  margin-bottom: 30px;
}

.welfare-society-testimonials-wrapper {
  overflow: hidden;
  padding: 0 0 80px;
}

.welfare-society-testimonials-wrapper .testimonial-slider-wrapper::after {
  top: -340px;
}

.welfare-society-testimonials-wrapper .foreign-guests-main::after {
  top: 0;
}

.foreign-guests-image.welfare-img {
  width: 40%;
}

.foreign-guests-contents.welfare-contents {
  width: 60%;
}

.foreign-guests-contents.welfare-contents p {
  font-size: 13px;
  line-height: 24px;
  font-weight: 400;
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0 0 100px;
}

.inner-banner-caption h1 {
  color: #fff;
  font-size: 50px;
  position: relative;
  margin: 0 0 20px;
}

.inner-banner-caption h1:after {
  position: absolute;
  content: "";
  left: 50%;
  margin-left: -25px;
  bottom: -7px;
  width: 50px;
  height: 4px;
  background: #fff;
}

.about-text h2 {
  text-transform: uppercase;
}

/* EOF About Us Page CSS */

/* BOF Services page CSS */

.service-listpage .services-list a {
  flex-wrap: wrap;
  min-height: 110px;
  padding: 10px 15px 0;
}

.service-listpage .services-overlay {
  position: relative;
  opacity: 1;
  background: no-repeat;
  padding: 0;
  width: 100%;
}

.service-listpage .services-overlay p {
  color: #333;
  padding: 10px 0;
}

/* EOF Services page CSS */

/* BOF Services Details page CSS */

.services-detail-wrapper img {
  width: 100%;
}

.services-detail-wrapper p {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
}

.services-detail-wrapper ul li {
  width: 100% !important;
}

ul li {
  font-size: 14px;
  font-weight: 400;
  list-style: none;
  position: relative;
  margin: 0 0 6px;
  padding: 0 10px;
}

ul li::before {
  position: absolute;
  content: "";
  left: 0;
  top: 8px;
  width: 5px;
  height: 5px;
  background: #804c9c;
}

.services-list-area {
  background: #fdfdfd;
  padding: 10px 15px 2px;
  margin: 0 0 20px;
  border: 1px solid #efebeb;
}

.services-detail-wrapper ul {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 10px;
}

.services-detail-wrapper ul li {
  width: 50%;
  display: inline-flex;
  padding: 0 15px !important;
  list-style: none;
}

.services-detail-wrapper ul li:before {
  content: "" !important;
  left: 0;
  width: 5px;
  margin: 0 0 0 3px !important;
  top: 6px;
}

/* EOF Services Details page CSS */

/* BOF Contact Page CSS */

.form-group {
  margin-bottom: 0.8rem;
}

.contact-map {
  height: 400px;
  position: relative;
  margin: 0px 0 -50px;
}

/* .contact-map:before, .contact-map:after {
position: absolute;
content: "";
right: 0;
bottom: 0;
background: rgba(48, 161, 16, 0.4);

}
.contact-map:before {
width: 400px;
height: 100%;
} */

input,
textarea,
input.form-control,
textarea.form-control {
  border-radius: 0;
  font-size: 13px;
  text-transform: none;
  resize: none;
  height: 100%;
  padding: 10px 15px;
  max-height: 100px;
  /* border: none; */
  border: 1px solid #ced4da;
  border-radius: 10px;
}

.common-btn {
  background: #31a110;
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  padding: 7px 35px;
  display: inline-block;
  border-radius: 20px;
}

.form-head {
  margin: 0 0 20px;
}

.form-head h4 {
  margin: 0 0 5px;
  color: #333;
  font-weight: 600;
  border: none;
}

.form-head h6 {
  font-size: 14px;
  color: #4553cd;
}

.contact-form,
.contact-address {
  width: 85%;
  margin: 0 0 20px 0;
  color: #fff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.contact-name {
  padding-right: 8px;
}

.contact-phone {
  padding-right: 8px;
}

@media (max-width: 575px) {
  .contact-form {
    width: 85%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .contact-name {
    padding-right: 0px;
  }

  .contact-phone {
    padding-right: 0px;
  }
}

.contact-address {
  padding: 26px 20px;
  /*background: rgba(49, 161, 16, 0.9);*/
  border: 1px solid rgba(51, 51, 51, 0.2);
  border-radius: 10px;
  margin: 20px 0 0;
  position: relative;
}

/*.contact-address:after {
position: absolute;
content: "";
left: 0;
height: 210%;
width: 100%;
top: -101px;
background: rgba(49, 161, 16, 0.9);
z-index: -1;
}*/

.contact-address h4 {
  color: #222;
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 30px;
}

.contact-address h4.bottom-border:after {
  background: #222;
}

.contact-address address {
  display: flex;
  flex-grow: 1;
  margin: 0 0 20px;
  align-items: flex-start;
  justify-content: space-between;
}

.contact-address address h5 {
  font-size: 14px;
  width: 70px;
  font-weight: 500;
}

.contact-address address h5,
.contact-address address p {
  color: #222;
  margin: 0;
}

.contact-address address p {
  width: 70%;
  margin: -4px 0 0;
  line-height: 20px;
}

.contact-address address p a {
  color: #222;
}

.contact-address address p a:hover {
  text-decoration: underline;
  color: #803783;
}

.map-overlay {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #8061B6;
  width: 100%;
  max-width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
}

.map-overlay h5 {
  color: #fff;
}

.map-overlay ul li {
  color: #fff;
}

.map-overlay ul li::before {
  background: #fff;
}

.career-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #999;
  border-radius: 60px;
  min-height: 80px;
  margin: 0 0 50px;
  padding: 0 30px;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  box-shadow: 0px 6px 6px #ddd;
}

.career-wrapper a.btn.big-btn {
  width: 200px;
}

.career-wrapper h4 {
  margin: 0;
}

.career-wrapper p {
  padding: 0 20px;
  border: 1px solid #ddd;
  border-top: none;
  border-bottom: none;
  margin: 0 20px;
}

.career-wrapper .btn {
  min-width: 130px;
}

/* EOF Contact Page CSS */

/* BOF Products Category Page CSS */

.category-section {
  width: 45%;
  display: inline-block;
  margin: 0 1% 2%;
  position: relative;
}

.category-section figure {
  margin: 0;
}

.category-section img {
  width: 100%;
}

.category-section figcaption {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background: rgba(0, 0, 0, 0.7);
}

.category-section figcaption a {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.4em;
  text-align: center;
}

.pages-text {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filter-area label {
  width: 25%;
}

.filter-area select {
  border-bottom: 1px solid #ddd;
  border-radius: 0 !important;
}

/* EOF Products Category Page CSS */

/* BOF News & Events Page CSS */

.pagination-wrapper {
  display: flex;
  width: 100%;
  margin: 30px 0 0;
  font-size: 13px;
  border-top: 1px solid #ddd;
  padding: 20px 0 0;
}

.pagination-wrapper .row {
  width: 100%;
}

.pagination li,
.pagination li a {
  font-size: 11px;
  color: #333;
  line-height: 21px;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
}

.pagination li {
  margin: 0 4px 0 0;
  padding: 4px 10px 3px;
  border: 1px solid #ddd;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
}

.pagination li::before {
  width: 0;
  height: 0;
}

.pagination li.active {
  background: #803783;
  border-color: #803783;
}

.pagination li:hover {
  background: #803783;
  border-color: #803783;
  color: #fff;
}

.pagination li:hover span,
.pagination li:hover a {
  color: #fff;
}

.pagination li.active span {
  color: #fff;
}

.news-events-single {
  margin: 0 0 35px;
}

.popular-news-events-wrapper {
  background: #fff;
  box-shadow: 0px 6px 10px #ddd;
  padding: 10px 10px 0;
}

.popular-news-events-card {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  margin: 0 0 15px;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid rgba(221, 221, 221, 0.6);
  padding: 0 0 15px;
}

.popular-card-image {
  /* width: 35%; */
  width: 90%;
}

.popular-news-events-card img.card-img-top {
  max-height: 100%;
  min-height: auto;
  height: 181px;
}

.popular-news-events-card .card-body.popular-card-body {
  padding-bottom: 0;
  padding: 11px;
}

.popular-card-image span.date-wrapper {
  font-size: 10px;
  padding: 2px 5px 2px 7px;
}

.popular-card-body {
  /* width: 65%; */
  width: 96%;
  padding: 0 10px;
}

.popular-card-body .card-title {
  font-size: 14px;
  margin: 0 0 5px;
  border-width: 2px;
  padding: 0 0 0 4px;
}

.popular-card-body .card-text {
  font-size: 12px;
  line-height: 16px;
}

.popular-card-body a:hover .card-title,
.popular-card-body a:hover .card-text {
  color: #804c9c;
  border-color: #804c9c;
}

/* EOF News & Events Page CSS */

/* BOF Gallery Page CSS */

.gallery-block-wrapper {
  margin: 0 0 30px;
}

.gallery-block {
  height: 100%;
}

.gallery-thumbs {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background: #fff;
  border: 5px solid #ffff;
  box-shadow: 0px 5px 5px #ddd;
  height: 100%;
}

.gallery-thumbs img {
  width: 100%;
  border: 1px solid #fff;
  max-height: 250px;
  height: 100%;
}

.gallery-category-name {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all ease 1s;
  -webkit-transition: all ease 1s;
  -moz-transition: all ease 1s;
  -ms-transition: all ease 1s;
  -o-transition: all ease 1s;
  padding: 10px;
}

.gallery-block:hover .gallery-category-name {
  background: rgba(0, 0, 0, 0.4);
}

.gallery-category-name h3 {
  margin: 0 0 7px;
}

.gallery-category-name h3 a {
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  display: block;
  text-align: center;
}

.more-link a i {
  background: #fff;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
}

.image-gallery-wrapper a {
  display: inline-block;
  height: 100%;
  width: 100%;
  border: 5px solid #ffff;
  box-shadow: 0px 5px 5px #ddd;
}

#lightgallery img.img-responsive {
  width: 100%;
  height: 100%;
  max-height: 220px;
}

#lg-share ul.lg-dropdown {
  display: none;
}

.lg-sub-html h4,
.lg-sub-html p {
  color: #fff;
}

.image-gallery-wrapper {
  margin: 0 0 20px;
}

/* EOF Gallery Page CSS */

/* BOF Product Details Page */

.related-products-wrapper {
  margin: 0 0 100px;
}

.related-products-wrapper .product-slider-main {
  border: 1px solid #ddd;
}

.lightSlider {
  height: 500px !important;
  overflow: hidden;
}

/* EOF Product Details Page */

/* BOF Testimonial Page CSS */

.testimonials-wrapper {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: space-between;
}

.testimonial-single {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px 15px 6px;
  position: relative;
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  margin: 0 0 30px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.testimonial-single::after {
  position: absolute;
  content: "";
  left: 30px;
  bottom: -10px;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #ddd;
  transform: rotate(45deg);
  border-top: none;
  border-left: none;
}

.testimonial-single p.testimonial-content {
  margin: 0 0 5px;
}

.testimonial-single .testimonial-name i,
.testimonial-single .testimonial-name strong {
  color: #804c9c;
}

/* .testimonial-name strong {
    white-space: nowrap;
} */

.testimonials-cards-wrapper .card-columns .card {
  border: none;
  margin: 0;
}

.testimonials-videos-section {
  margin: 0 0 20px;
}

.testimonials-videos-section iframe {
  border: 5px solid #fff;
  box-shadow: 0px 5px 5px #ddd;
}

.testimonial-name {
  display: flex;
  align-items: center;
}

.testimonial-avatar-image-small {
  display: inline-block;
  width: 50px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  box-shadow: 0px 4px 5px #ddd;
  margin: 0 10px 0 0;
}

/* EOF Testimonial Page CSS */

.mt-20 {
  margin-bottom: 20px;
}

/* EOF Custom Radio Buttons */

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  margin: 12px 10px 0 0;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.booknow-popup-wrapper {
  padding: 61px;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .booknow-popup-wrapper {
    padding: 40px;
    font-family: Roboto;
  }
}

.booknow-popup-wrapper h5 {
  margin: 0 0 41px;
  position: relative;
  color: #292929;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .booknow-popup-wrapper h5 {
    font-size: 22px !important;
    margin-bottom: 22px;
  }
}

.form-input-field input,
textarea {
  height: 43px;
  font-weight: 400;
  font-size: 14px;
}

#formTextArea {
  font-size: 14px;
}

@media (max-width: 575px) {
  #formTextArea {
    font-size: 14px;
  }
}

/* .booknow-popup-wrapper h5::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: -4px;
  width: 30px;
  height: 3px;
  background: #e73e97;
} */

/* Hide the browser's default radio button */

.radio-container input {
  position: absolute;
  opacity: 1;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  /* position: absolute; */
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.radio-container input:checked ~ .checkmark {
  background-color: #e44396;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.radio-container .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

/* .radio-container .checkmark:after {
top: 9px;
left: 9px;
width: 8px;
height: 8px;
border-radius: 50%;
background: white;
} */

/* EOF Custom Radio Buttons */

/* BOF Success Stories Page */

.success-stories .card {
  border: none;
}

.card-single .news-events-card {
  box-shadow: none;
  margin: 0;
}

.card-single .card-body {
  padding: 10px 0 15px;
}

.card-single li {
  font-size: 13px;
}

.card-head {
  margin: 0 0 10px;
  position: relative;
}

.card-head::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -2px;
  width: 30px;
  height: 1px;
  background: #333;
}

/* EOF Success Stories Page */

/* BOF Careers Page */

.job-name {
  margin: 0;
}

.job-listing-wrapper {
  border-bottom: 1px solid #ddd;
  margin: 0 0 30px;
  padding: 15px 20px;
  background: #fff;
  box-shadow: 0px 4px 13px rgba(204, 204, 204, 0.3);
  width: 100%;
}

.job-name {
  margin: 0;
}

.required-experience {
  margin: 7px 0 9px;
  border-top: 1px solid #ddd;
  padding: 10px 0 0;
}

.job-listing li {
  font-weight: 300;
  font-size: 13px;
}

.jobs-listing-head {
  margin: 0 0 5px;
  font-size: 14px;
}

/* EOF Careers Page */

select {
  font-size: 13px !important;
  font-weight: 300 !important;
  height: 42px !important;
  border-radius: 10px !important;
  line-height: 32px !important;
  padding: 0 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
  -webkit-appearance: none;
  /* remove the strong OSX influence from Webkit */
}

.select-box {
  position: relative;
}

/* .select-box:after {
  position: absolute;
  content: "";
  right: 10px;
  border: 6px solid transparent;
  border-top: 10px solid #495057;
  top: 15px;
  z-index: 9;
} */

.article-img {
  margin: 0 0 15px;
}

.form-control.error {
  border-color: rgba(238, 13, 13, 1);
  box-shadow: 0 0 4px 0 rgba(238, 13, 13, 0.5);
}

/* tooltip error */

[data-tip] {
  position: relative;
}

[data-tip]:before {
  content: "";
  /* hides the tooltip when not hovered */
  display: none;
  content: "";
  display: none;
  /* border-left: 5px solid transparent;
border-right: 5px solid transparent;
border-bottom: 5px solid white; */
  position: absolute;
  top: 30px;
  left: 35px;
  z-index: 8;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
  position: absolute;
  top: 30px;
  left: 35px;
  z-index: 8;
  font-size: 0;
  line-height: 0;
  width: 0;
  height: 0;
}

[data-tip]:after {
  display: none;
  content: attr(data-tip);
  position: absolute;
  top: -60%;
  left: 70%;
  padding: 5px 8px;
  background: transparent;
  color: red;
  z-index: 9;
  font-size: 0.75em;
  height: 18px;
  line-height: 18px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  white-space: nowrap;
  word-wrap: normal;
}

[data-tip]:hover:before,
[data-tip]:hover:after {
  display: block;
}

.form-group {
  /* margin-bottom: 1rem; */
}

.form-control.error {
  box-shadow: 0 0 4px 0 rgba(238, 13, 13, 0.5);
}

[data-tip]:after {
  top: initial;
  right: 0;
  padding: 0;
  left: initial;
  bottom: -16px;
  font-size: 10px;
}

.error-form-data {
  color: red;
  font-size: small;
  font-weight: 500;
  padding: 0.5em 0;
  margin: 5px 5px;
}

.success-form-data {
  color: green;
  font-size: small;
  font-weight: 200;
  padding: 0.5em 0;
  font-weight: 500;
  margin: 5px 5px;
}

.consult-alert {
  /* padding: 3em; */
  width: 100%;
  text-align: center;
}

.consult-msg {
  margin-bottom: 1em;
}

.consult-alert .pay {
  display: flex;
  justify-content: space-evenly;
}

.btn.inverse {
  background: #fff;
  color: #e54397;
  border-color: #e54397;
}

.btn.inverse:hover,
.btn.inverse:focus {
  background: rgb(249, 246, 246);
  color: #e54397;
}

/* Inner Html Content */

.html-success p img {
  width: 100%;
}

/* Inner Html Content */

.contact-message {
  animation: fadeInUp 0.4s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Sub Heading box design */

.short-discription {
  border: 1px solid #ddd;
  font-size: 12px !important;
  line-height: 20px !important;
  font-style: italic;
  padding: 10px;
  border-radius: 10px;
}

/* Sub Heading box design */

.modal {
  background: rgba(0, 0, 0, 0.5) !important;
}

.newsevents-slider .owl-stage {
  display: flex;
}

.newsevents-slider .owl-carousel .owl-item {
  display: flex;
  flex: 1 0 auto;
}

.newsevents-slider .item {
  height: 100%;
  display: inline-flex;
  /* new */
  width: 100%;
}

.react_lightgallery_item {
  display: inline-block;
  height: 100%;
  width: 100%;
  border: 5px solid #fff;
  box-shadow: 0 5px 5px #ddd;
  /* new */
  min-height: 210px;
  position: relative;
}

.react_lightgallery_item img {
  width: 100%;
  height: 100%;
  max-height: 210px;
  min-height: 210px;
}

.ql-editor {
  height: auto;
  padding-left: 0;
  padding-right: 0;
}

.lazy-image-overlay {
  position: absolute;
  filter: blur(1px);
  opacity: 1;
  /* transition: opacity ease-in 1000ms; */
  transition: all ease-in 900ms;
  clip-path: inset(0);
}

.modal-backdrop {
  width: 100%;
  height: 100%;
}

.ql-editor ol,
.ql-editor ul {
  padding: 0;
}

.ql-editor ul > li::before {
  content: "";
  width: 5px;
  top: 6px;
}

.ql-editor li:not(.ql-direction-rtl)::before {
  margin-left: 0;
  margin-right: 0;
}

.ql-editor ol > li,
.ql-editor ul > li {
  padding: 0 15px;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 15px;
}

.about-wrapper .page.ql-editor img {
  /* width: 100% !important; */
  float: left;
  margin: 0 15px 0 0;
}

.about-outer{
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 575px) {
  .about-wrapper .page.ql-editor img {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  .modal-dialog.video-container button.close {
    top: -19px;
    right: -16px;
  }

  .modal-dialog.video-container-booking button.close {
    top: -19px;
    right: -16px;
  }

  .modal-dialog.video-container {
    margin: 0 20px !important;
    max-width: 100%;
  }

  .modal-dialog.video-container-booking {
    margin: 5em 2em 3em 1.5em !important;
    max-width: 100%;
  }
}

@media (max-width: 991px) {
  .modal-dialog.video-container-booking {
    margin: 0 20px !important;
    max-width: 95%;
  }
}

/* Payment Status design */
.payment-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.payment-status-container h1 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
}

.payment-status-green {
  /* background-color: #4dd64d; */
  height: 35rem;
  width: 41.875rem;
  border-radius: 1.875rem;
  margin: 1.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-status-red {
  background-color: #d64d4d;
  height: 25rem;
  width: 21.875rem;
  border-radius: 1.875rem;
  margin: 1.875rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .payment-status-green {
    width: 85%;
  }

  .payment-status-red {
    width: 85%;
  }
}

.dep-listing {
  background: #ffffff;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.25);
  border-radius: 91px;
  margin: 0 0;
  padding: 5px 25px;
}

.dep-listing-typable {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.25);
  border-radius: 91px;
  margin: 0 0;
  padding: 5px 25px;
  width: 540px;
}

.custom-department {
  width: 410px;
}

@media (max-width: 927px) {
  .dep-listing-typable {
    width: 350px;
  }

  .custom-department {
    width: 310px;
  }
}

/* .custom-department .css-tj5bde-Svg{
  display: none!important;
}

.custom-department .css-1hb7zxy-IndicatorsContainer{
  display: none!important;
} */

.inner-flex {
  background: #ffffff;
  box-shadow: 0px 4px 250px rgba(0, 0, 0, 0.25);
  border-radius: 91px;
  margin: 30px 0;
  padding: 5px 25px;
  width: 50%;
}

#branchSelection {
  margin-bottom: 40px;
}

.arrowsvg {
  height: 7px;
}

.bedsvg {
  height: 40px;
  margin: 5px 10px;
}

.outer-flex {
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.cutoff {
  width: 410px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: none;
}

.title-adder {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.title-adder p {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  color: #e54397;
}

@media (max-width: 991px) {
  .outer-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .cutoff {
    width: 200px;
  }

  .dep-listing {
    margin: 10px 0;
  }
}

@media (max-width: 767px) {
  .outer-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .cutoff {
    width: 180px;
  }

  .dep-listing {
    margin: 10px 0;
  }
}

@media (max-width: 575px) {
  .outer-flex {
    /* width: 90%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .cutoff {
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
  }

  .dep-listing {
    margin: 10px 0;
  }
}

.slot-right button {
  background-color: #803783;
  color: white;
  border-radius: 8px;
  font-weight: 700;
  border: 1px solid #803783;
  transition: 0.3s linear all;
  padding: 8px 20px;
  width: 750px;
  height: 65px;
  font-family: Roboto;
  font-size: 20px;
  margin-top: 21px;
}

@media (max-width: 900px) {
  .slot-right button {
    width: 610px;
    height: 99px;
    font-size: 16px;
    margin-top: 15px;
  }
}

@media (max-width: 575px) {
  .slot-right button {
    width: 285px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    height: 58px;
    margin-top: 5px;
  }

  .details-select {
    width: 285px;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    z-index: 9999999;
    margin-top: 4px !important;
  }
}

.details-select {
  margin-top: 11px;
}

.slot-right button:hover {
  background-color: white;
  color: #803783;
}

.active-image {
  display: inline-block;
  height: 20px;
  margin-left: -10px;
  margin-right: 10px;
}

.inactive-image {
  display: none;
}

/* .slot-right button:hover{
  color: #E54397;
  background-color: white;
} */

#slotfiller {
  font-size: 13px;
  width: 200px;
  margin-top: 15px;
}

#doctortitle {
  display: inline-block;
  color: #2d2b2b;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Roboto;
  margin-bottom: 5px;
}

.details-dept {
  color: #2d2b2b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 35px;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .details-dept {
    margin-bottom: 23px;
  }

  .react-select-container {
    width: 100px !important;
  }
}

.details-qual {
  color: #777676;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.details-slot {
  width: 235px;
  height: 77px;
  border-radius: 8px;
  border: 0.6px solid #000;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.details-slot p {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 6px;
  font-family: Roboto;
}

.details-slot span {
  color: #803783;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: Roboto;
}

.slot-right {
  display: flex;
  align-items: start;
  margin-bottom: -15px;
  flex-direction: column;
  width: 100%;
}

@media (max-width: 575px) {
  .details-slot {
    width: 285px;
    margin-left: 0px;
    height: 62px;
  }

  .slot-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
  }
}

@media (max-width: 575px) {
  .slot-right {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    /* justify-content:space-between; */
    align-items: start;
  }
}

.my-modal {
  width: 70%;
  /* Occupy the 90% of the screen width */
}

.otp-wrapper {
  display: flex;
  flex-direction: column;
  padding: 64px 78px 84px 80px;
  margin-bottom: 10px;
}

.otp-wrapper-otp {
  display: flex;
  flex-direction: column;
  padding: 83px 83px 62px 83px;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .otp-wrapper-otp {
    display: flex;
    flex-direction: column;
    padding: 50px;
    margin-bottom: 10px;
  }

  .otp-wrapper {
    display: flex;
    flex-direction: column;
    padding: 30px;
    margin-bottom: 0px !important;
  }

  .phone-input {
    width: 100%;
  }
}

.otp-wrapper h3 {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
}

.otp-wrapper-otp h3 {
  font-size: 16px;
  font-family: Roboto;
  font-weight: 400;
}

.otp-wrapper h4 {
  font-size: 16px;
  margin-top: 10px;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: 21px;
}

.otp-wrapper-otp h4 {
  font-size: 16px;
  margin-top: 10px;
  font-family: Roboto;
  font-weight: 700;
  margin-bottom: 21px;
}

@media (max-width: 575px) {
  .otp-wrapper-otp h3 {
    font-size: 14px;
  }

  .otp-wrapper-otp h4 {
    font-size: 15px;
  }

  .otp-wrapper-otp button {
    height: 53px !important;
    font-size: 14px !important;
  }
}

.otp-wrapper input {
  height: 69px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #413f3f;
}

@media (max-width: 575px) {
  .otp-wrapper input {
    font-size: 14px;
    height: 57px;
  }

  .otp-wrapper button {
    height: 57px !important;
    font-size: 14px !important;
    margin-top: 12px !important;
  }
}

.otp-wrapper-otp input {
  height: 69px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  color: #413f3f;
}

.otp-wrapper button {
  font-size: 16px;
  background-color: #803783;
  color: white;
  border-radius: 8px;
  padding: 5px;
  border: none;
  padding: 8px 0px;
  margin-top: 18px;
  height: 62px;
  font-weight: 900;
  font-family: Roboto;
}

.doctor-name {
  margin-top: 4px;
}

@media (max-width: 575px) {
  .doctor-name {
    margin-top: 0px;
  }
}

.otp-wrapper-otp button {
  font-size: 16px;
  background-color: #803783;
  color: white;
  border-radius: 8px;
  padding: 5px;
  border: none;
  padding: 8px 0px;
  margin-top: 18px;
  height: 62px;
  font-weight: 700;
  font-family: Roboto;
  width: 100%;
}

@media (max-width: 575px) {
  .otp-wrapper-otp button {
    width: 100%;
  }
}

.otp-wrapper button:hover {
  background: #800649;
  color: #ffffff;
}

.otp-wrapper-otp button:hover {
  background: #800649;
  color: #ffffff;
}

.phone-input-wrapper {
  width: 100% !important;
  margin: auto;
}

.form-group-flex {
  display: flex;
  gap: 20px;
}

.phone-input {
  width: 79px !important;
  height: 79px !important;
  /* padding: 10px; */
}

@media (max-width: 575px) {
  .phone-input {
    width: 51px !important;
    height: 51px !important;
  }
}

#checker {
  margin: 0px;
  padding: 0px;
  width: 80px;
  margin-top: 6px;
}

@media (max-width: 575px) {
  .phone-input-wrapper {
    width: 100% !important;
  }

  .phone-input {
    margin-right: 0px !important;
  }
}

.form-group-condition {
  display: flex;
}

.resendOtp {
  display: flex !important;
  justify-content: center !important;
}

.trai-guide {
  font-size: 12px;
}

.resendText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-top: 22px;
  font-family: Roboto;
  margin-bottom: 0;
  /* text-align: center !important; */
}

.resendText span {
  font-size: 16px;
  font-weight: 700;
  color: #803783;
  margin-top: 10px;
  font-family: Roboto;
}

.resendText span {
  color: #803783;
  cursor: pointer;
}

.resendText span.disabled {
  color: #803783;
  cursor: not-allowed;
}

/* confirmation modal */

.confirm-title {
  padding: 50px 30px 16px;
  text-align: center;
  margin: 0;
  font-size: 32px;
  color: #000;
  font-weight: 700;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .confirm-title {
    font-size: 22px;
  }
}

.pay-later-button {
  border: 1px solid #803783;
  background-color: white;
  padding: 15px;
  width: 100%;
  font-weight: 700;
  transition: 0.3s linear all;
  color: #803783;
  border-radius: 8px;
  font-family: ROboto;
  height: 62px;
  font-size: 18px;
}

.pay-later-button:hover {
  background-color: #800649;
  color: white;
}

@media (max-width: 575px) {
  .pay-later-button {
    height: 52px;
    font-size: 15px;
  }
}

.cancel-appoint h6 {
  margin: 25px 30px;
  text-align: center;
  color: red;
  cursor: pointer;
}

.row-data {
  margin: 25px 61px;
  line-height: 15px;
}

@media (max-width: 575px) {
  .row-data {
    margin: 8px 20px;
  }
}

.left-title {
  color: #6e7485;
  font-size: 20px;
  font-weight: 500;
  font-family: Roboto;
}

.right-title {
  font-weight: 500;
  font-size: 20px;
  color: #000;
  font-family: Roboto;
}

.complete-transaction {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 40px;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .complete-transaction {
    font-size: 14px;
    width: 300px;
    text-align: center;
    margin-bottom: 18px;
  }
}

.info-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 15px;
}

@media (max-width: 575px) {
  .left-title {
    color: #6e7485;
    font-size: 14px;
    line-height: 1.2;
    font-weight: 600;
  }

  .right-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.2;
  }

  .pay-now-div {
    margin: 10px 20px !important;
  }

  .pay-later-div {
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 14px;
    margin-bottom: 18px;
  }

  .pay-not-available {
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-top: 14px;
    margin-bottom: 18px;
  }
}

.pay-now-div {
  margin: 10px 61px;
}

.pay-later-div {
  margin: 0px 61px;
  margin-top: 14px;
  margin-bottom: 18px;
}

.pay-not-available {
  margin: 0px 61px;
  margin-top: 14px;
  margin-bottom: 18px;
}

.pay-now-button {
  /* border: 1px solid #E51188; */
  border: none;
  border-radius: 8px;
  background-color: #803783;
  padding: 15px;
  width: 100%;
  font-weight: 700;
  transition: 0.3s linear all;
  color: white;
  margin-top: 26px;
  font-family: Roboto;
  height: 62px;
  font-size: 18px;
}

.pay-not-available-button {
  border: none;
  border-radius: 8px;
  background-color: #b3b3b3;
  padding: 15px;
  width: 100%;
  font-weight: 700;
  transition: 0.3s linear all;
  color: white;
  font-family: Roboto;
  height: 62px;
  font-size: 18px;
}

@media (max-width: 575px) {
  .pay-not-available-button {
    font-size: 15px;
    height: 52px;
  }
}

.pay-now-button:hover {
  background-color: #800649;
  color: white;
}

@media (max-width: 575px) {
  .pay-now-button {
    height: 52px;
    font-size: 15px;
    margin-top: 20px;
  }
}

/* Footer */
.lab-report {
  color: white;
  font-size: 13px;
  /* padding: 5px; */
  border-radius: 5px;
  display: block;
  font-weight: 400px;
  margin: 22px 0;
  list-style-type: none;
  cursor: pointer;
  height: 30px;
}

.lab-reports {
  color: white;
  font-size: 13px;
  /* padding: 5px; */
  border-radius: 5px;
  display: block;
  font-weight: 400px;
  margin-top: 0;
  list-style-type: none;
  cursor: pointer;
  height: 30px;
}

.lab-report:hover {
  color: #979191;
}

.lab-reports:hover {
  color: #979191;
}

.lab-title {
  text-align: left;
  margin-bottom: 0px;
  color: black;
  font-weight: 700 !important;
  font-size: 28px;
  margin-bottom: 10px;
  font-family: Roboto;
}

.download-lab {
  font-weight: 400;
  font-size: 16px;
  color: #545454;
  text-align: left;
  margin-bottom: 15px;
  font-family: Roboto;
  margin-bottom: 23px;
}

@media (max-width: 575px) {
  .download-lab {
    font-size: 14px;
    margin-bottom: 9px;
  }
}

.lab-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.lab-div {
  padding-top: 54px;
  padding-left: 66px;
  padding-bottom: 22px;
  padding-right: 65px;
}

.lab-details input {
  width: 100%;
  height: 69px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 400;
}

@media (max-width: 575px) {
  .lab-div {
    padding: 35px;
  }

  .lab-title {
    font-size: 22px !important;
    margin-bottom: 0;
  }

  .details-from-bill {
    text-align: left;
    margin-top: 7px !important;
  }

  .lab-details input {
    font-size: 14px;
  }

  .lab-password input {
    font-size: 14px !important;
  }

  .lab-details button {
    font-size: 14px !important;
  }
}

.lab-details button {
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  font-weight: 700;
  color: white;
  background-color: #803783;
  margin-bottom: -15px;
  transition: all 0.3s;
  border: 1px solid #803783;
  height: 62px;
  font-size: 16px;
  font-family: Roboto;
}

.lab-report-container {
  height: 484px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lab-report-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lab-details button:hover {
  background-color: white;
  color: #803783;
}

.details-from-bill {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 400;
  margin-top: 38px;
  margin-bottom: 0;
  font-family: Roboto;
}

#bill-status {
  font-size: 15px;
  font-weight: 500;
  text-decoration: underline;
}

.bill-flex {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.bill-download {
  background-color: white;
  color: #803783;
  font-size: 13px;
  border: 1px solid #803783;
  padding: 3px;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 400;
  transition: all 0.3s;
}

.bill-download:hover {
  background-color: #803783;
  color: white;
}

.bill-hide {
  display: none;
}

.lab-user-name {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
  font-size: 13px;
}

.lab-user-name .input {
  width: 100%;
  height: 69px;
}

.lab-password {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.lab-loading p {
  font-size: 15px;
  font-weight: 400;
}

.lab-password input {
  width: 100%;
  height: 69px;
  font-size: 16px;
}

.lab-loading img {
  height: 25px;
}

.lab-loading {
  display: flex;
  gap: 10px;
}

.lab-loading p {
  font-size: 15px;
  font-weight: 400;
}

.lab-loading-hide {
  display: none;
}

/* visits */

.slot-list {
  background-color: #152775;
  padding: 10px;
  color: white;
  display: inline-block;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
}

.slot-list p {
  color: white;
  font-size: 15px;
}

.slot-list img {
  height: 25px;
}

.inside-slot {
  display: flex;
  flex-direction: column;
}

.patient-selection {
  /* display: grid;
  grid-template-columns: repeat(3, minmax(240px, 1fr)); */
  display: flex;
  flex-direction: column;
}

@media (max-width: 992px) {
  .patient-selection {
    display: grid;
    grid-template-columns: repeat(2, minmax(240px, 1fr));
  }

  .bill-flex {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .patient-selection {
    display: grid;
    grid-template-columns: repeat(1, minmax(240px, 1fr));
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  -webkit-background-clip: border-box;
  background-clip: border-box;
  border: 1px solid #e0dbdb;
  border-radius: 0.25rem;
  justify-content: space-between;
}

.card.card-active {
  /* border: 2px solid #e51188; */
}

/* .patient-list{
  border: none !important;
} */

.card .card-active-dot {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  width: 16px;
  height: 16px;
}

.card p {
  margin-bottom: 0px;
}

.left-column {
  color: #6e7485;
  font-size: 13px;
  font-family: Roboto;
  font-weight: 700;
}

.right-column {
  /* font-weight: bold; */
  font-size: 13px;
  font-family: Roboto;
}

.p-10 {
  padding: 10px !important;
}

/* .mb-10 {
  margin-bottom: 10px !important;
} */

.gap-2 {
  gap: 0.5rem !important;
}

.d-flex {
  display: flex;
}

.fd-row {
  flex-direction: row;
}

.btn-white {
  font-weight: 400;
  background: #fff;
  border: 1px solid #e0dbdb;
  color: #6b6a6a;
  padding: 0 16px;
  max-height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 12px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-left: auto;
}

.btn i {
  color: #fff;
  margin-left: 10px;
}

.btn:hover,
.btn:focus {
  background: #e51188;
  color: #ffffff;
}

/* Loading div */

.loading-div {
  height: 100vh;
  z-index: 1;
}

.no-data-show {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}

.slot-list-hide {
  display: none;
}

.slot-list-hide-mobile {
  display: none;
}

.slot-list-display {
  display: none;
}

.out-false {
  display: none;
}

.out-false-mobile {
  display: none;
}

.out-true {
  justify-content: center;
  color: white;
  background-color: #803783;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 280px;
  height: 38px;
  margin-top: 8px;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.out-true-mobile {
  justify-content: center;
  color: white;
  background-color: #803783;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: 219px;
  height: 38px;
  margin-top: 0;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
}

.DatePicker_dateDayItem__28uSC {
  width: 50px !important;
  height: 50px !important;
}

.book-appointment {
  color: #ffffff;
  background-color: #803783;
  padding: 0.6rem 1rem;
  margin-top: 10px;
  border-radius: 8px;
  display: inline-block;
  font-size: 14px;
}

.header-call {
  display: flex;
  flex-direction: row;
}

.header-call img {
  height: 36px;
  margin-top: 10px;
}

.header-labreport {
  color: #fff;
  font-size: 18px;
}

/* .custom-date-label{
  color: #E54397!important;
  font-weight: bold;
} */

.weekend-item {
  color: red !important;
}

.FC {
  background-color: #803783 !important;
}

.FC ._1g {
  background-color: #803783 !important;
  color: #fff;
}

.Ez:after {
  left: 24px;
  background: none !important;
}

.jb {
  color: #803783;
}

.select-filter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  padding: 0 10px;
}

@media (max-width: 900px) {
  .select-filter {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 0 10px;
  }
}

@media (max-width: 575px) {
  .select-filter {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
    align-items: center;
    gap: 10px;
    padding: 0;
  }

  .filter-Select {
    width: 500px;
  }
}

.doc-name {
  font-size: 16px;
  width: 380px;
  color: #2d2b2b;
  font-weight: 700;
  text-align: left;
  margin-bottom: 0;
  font-family: Roboto;
}

.doc-name-mobile {
  font-size: 16px;
  width: 270px;
  color: #2d2b2b;
  font-weight: 700;
  text-align: left;
  margin-bottom: -2px;
  margin-top: 5px;
  font-family: Roboto;
}

.slot-image-div {
  margin-top: 8px;
}

@media (max-width: 575px) {
  .doc-name {
    display: none;
  }

  .dep-name {
    display: none;
  }
}

.dep-name {
  color: #2d2b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  font-family: Roboto;
  margin-top: 4px;
  margin-bottom: 21px;
}

.dep-name-mobile {
  color: #2d2b2b;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  font-family: Roboto;
  width: 300px;
  margin-bottom: 16px;
}

.slot-div {
  width: 330px;
  height: 56px;
  display: flex;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .slot-div {
    display: none;
    flex-direction: column;
    width: 80px;
    margin-bottom: 10px;
  }

  .slot-pos {
    width: 130px !important;
    border-radius: 5px !important;
  }

  .slot-white {
    width: 130px !important;
    border-radius: 5px !important;
  }

  .out-true {
    width: 130px;
    padding: 5px;
    display: none;
  }

  .doc-name {
    width: 150px;
  }

  .dep-name {
    width: 150px;
  }

  .doc-main-div {
    width: 200px !important;
  }
}

.figure-div {
  position: relative;
  display: flex;
  padding-left: 15px;
}

@media (max-width: 575px) {
  .figure-div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
}

.doc-main-div {
  display: flex;
  flex-direction: column;
  width: 416.5px;
  padding-left: 35px;
  padding-right: 30px;
}

.doc-main-div-doctors {
  display: flex;
  flex-direction: column;
  width: 416.5px;
  padding-left: 35px;
  padding-right: 30px;
  justify-content: center;
}

@media (max-width: 575px) {
  .doc-main-div {
    padding: 0 10px;
  }

  .doc-main-div-doctors {
    display: none;
  }

  .doctors-page-book {
    font-size: 12px !important;
    width: 140px !important;
  }
}

.slot-pos {
  width: 205px;
  border: 2px solid #e34597;
  background-color: #e34597;
  height: 56px;
  border-radius: 5px 0 0 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
}

.slot-white {
  width: 165px;
  border: 2px solid #e34597;
  background-color: #ffffff;
  border-radius: 0 5px 5px 0;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.slot-white p {
  margin-bottom: 0;
}

.slot-white-mobile p {
  margin-bottom: 0;
}

.clock-png {
  height: 20px !important;
}

.slot-time {
  margin-bottom: 0;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.slot-time-mobile {
  margin-bottom: 0;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0px;
}

.slot-inner {
  display: flex;
  flex-direction: column;
}

.slot-inner-mobile {
  display: flex;
  flex-direction: column;
}

.slot-count {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.slot-count-mobile {
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0;
}

.booking-comp {
  color: #803783;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.booking-comp-mobile {
  color: #803783;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mobile-verify {
  color: #292929;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .mobile-verify {
    font-size: 20px;
    margin-top: 0 !important;
    margin-bottom: 23px;
  }
}

.otp-title {
  color: #292929 !important;
  font-size: 28px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  font-family: Roboto;
}

@media (max-width: 575px) {
  .otp-title {
    font-size: 20px !important;
  }
}

.patient-item {
}

.next-step {
  border-radius: 8px;
  background: #803783;
  height: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 58px;
  align-items: center;
  padding: 0 10px;
  border: none;
}

.next-step span {
  color: #fff;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

@media (max-width: 575px) {
  .next-step {
    height: 51px !important;
  }

  .next-step span {
    font-size: 14px !important;
  }
}

.btn-form-next {
  height: 58px !important;
  border-radius: 8px !important;
  background: #803783 !important;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  cursor: pointer;
  height: 62px !important;
}

@media (max-width: 575px) {
  .btn-form-next {
    font-size: 15px;
    height: 52px !important;
  }
}

.form-age {
  padding-left: 5px;
}

.form-gender {
  padding-right: 5px;
}

@media (max-width: 900px) {
  .form-age {
    padding-left: 0 !important;
  }

  .form-gender {
    padding-right: 0 !important;
  }
}

@media (max-width: 575px) {
  .form-age {
    padding-left: 0 !important;
  }

  .form-gender {
    padding-right: 0 !important;
  }
}

[data-testid="otp-input-root"] {
  display: flex !important;
  justify-content: space-between;
}

@media (max-width: 575px) {
  [data-testid="otp-input-root"] {
    display: flex;
    margin-right: 0;
  }
}

.doctors-page-book {
  padding: 8px;
  width: 173px;
  background-color: #e34597;
  border-radius: 8px;
  border: 1px solid #e34597;
  color: white;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.doctors-page-book:hover {
  color: #e34597;
  background-color: white;
}

.doctor-detail-box {
  padding: 75px;
  box-sizing: border-box;
}

@media (max-width: 575px) {
  .doctor-detail-box {
    padding: 25px;
    box-sizing: border-box;
  }
}

.slot-image {
  background: url("/img/slot-image-desktop.svg");
  /* background-repeat: no-repeat; */
  height: 38px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 327px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  margin-left: -15px;
}

.slot-image-mobile {
  background: url("/img/slot-image-mobile.svg");
  /* background-repeat: no-repeat; */
  height: 43px;
  text-align: left;
  display: flex;
  align-items: center;
  width: 219px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

.select-slot-mobile {
  color: #803783;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  width: 219px;
  margin-bottom: 5px;
}

@media (max-width: 575px) {
  .slot-image {
    display: none;
  }
}

.slot-image p {
  margin-left: 56px;
  font-family: Roboto;
}

.slot-image-mobile p {
  margin-left: 45px;
  font-family: Roboto;
}

.slot-image span {
  color: #803783;
  font-family: Roboto;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  right: 23px;
  position: absolute;
  font-family: Roboto;
}

.slot-image-mobile span {
  color: #803783;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  right: 16px;
  position: absolute;
  font-family: Roboto;
}

.doc-qualification {
  text-align: left;
  color: #777676;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0;
}

.doc-qualification-details {
  text-align: left;
  color: #777676;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}

.doc-qualification-mobile {
  text-align: left;
  color: #777676;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
}

@media (max-width: 575px) {
  .doc-qualification {
    display: none;
  }
}

.mobile-doc-view {
  display: none;
}

@media (max-width: 575px) {
  .mobile-doc-view {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .mobile-banner-pic::after {
    content: "";
    background-image: url("/img/bubbles.svg");
    background-size: contain;
    position: absolute;
    width: 18px;
    height: 30px;
    /* bottom: -46px; */
    bottom: -395px;
    right: 14px;
  }
}

.mobile-container {
  width: 219px;
}

/*services*/

.service-home {
  width: 284px;
  height: 300px;
  padding-top: 44px;
  padding-bottom: 53px;
  padding-left: 36px;
  padding-right: 36px;
  background: white;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.11);
  border-radius: 32px;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 28px;
  margin-bottom: 35px;
}

.service-home:hover {
  border: 1px solid #804c9c;
}

/* .service-home:hover .service-title {
  color: white;
}

.service-home:hover .service-description {
  color: white;
}

.service-home:hover .service-image {
  background: white;
} */

.service-image {
  height: 44px !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 28px;
}

.service-description {
  text-align: center;
}

.service-image-mobile {
  display: none;
}

.service-title {
  color: #6d6a6a;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
  height: 84px;
  margin-bottom: 0;
}

.service-title-mobile {
  display: none;
}

.our-services {
  color: #804c9c;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  margin-top: 70px;
}

@media (max-width: 1600px) {
  .our-services {
    margin-top: 40px;
  }
}

.our-services-mobile {
  color: #804c9c;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 600;
  text-align: left;
  margin-left: 38px;
  margin-top: 40px;
}

.experience-the-best {
  color: #494444;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  width: 586px;
  height: 120px;
  margin-bottom: 60px;
}

.experience-the-best-mobile {
  color: #494444;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 28px;
  height: 56px;
  text-align: left;
  margin-bottom: 28px;
  margin-left: 38px;
}

.service-home-mobile {
  display: none;
}

.services-mobile {
  display: none;
}

@media (max-width: 575px) {
  .services-desktop {
    display: none;
  }

  .services-mobile {
    display: block;
  }

  .service-home-mobile {
    width: 225px;
    height: 281px;
    padding-top: 32px;
    padding-bottom: 47px;
    padding-left: 23px;
    padding-right: 23px;
    background: white;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
    border-radius: 32px;
    overflow: hidden;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 38px;
  }

  .service-title-mobile {
    display: block;
    color: #6d6a6a;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    text-align: center;
  }

  .service-image-mobile {
    display: block;
    height: 44px;
    width: auto !important;
  }

  .service-home-mobile p {
    text-align: center;
  }

  .service-home {
    display: none;
  }

  .our-services {
    color: #f76eb6;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    text-align: center;
  }

  .experience-the-best {
    color: #494444;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
    width: 100%;
  }
}

/* about us */

.about-diamond {
  width: 40px;
  height: 60px;
}

.about-us-wrapper {
  margin-top: 86.42px;
  width: 100%;
}

.about-us-title {
  color: #804c9c;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 0;
}

.a-legacy {
  color: #494444;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 40px;
}

.about-paragraph-one {
  color: #494444;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 22.4px;
  width: 440px;
  height: 88px;
  margin-bottom: 20px;
}

.about-paragraph-two {
  color: #494444;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 22.4px;
  width: 457px;
  height: 110px;
  margin-bottom: 24px;
}

.about-us-button {
  width: 153px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #803783;
  border-radius: 8px;
  gap: 10px;
  color: white;
  border: none;
}

.about-us-button:hover {
  width: 161px;
}

.about-us-button p {
  margin-bottom: 0;
  color: white;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 26px;
}

.about-flex {
  display: flex;
  gap: 107px;
  align-items: center;
  margin-left: 38px;
  justify-content: space-between;
}

.about-us-image {
  width: 573px;
  height: 547px;
  border-radius: 32px;
}

@media (max-width: 575px) {
  .about-diamond {
    display: none;
  }

  .about-us-title {
    color: #804c9c;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    width: 100%;
    margin-bottom: 8px;
  }

  .a-legacy {
    color: #494444;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 28px;
  }

  .about-paragraph-one {
    width: 306px;
    height: 144px;
    margin-bottom: 16px;
  }

  .about-paragraph-two {
    width: 306px;
    height: 158px;
  }

  .about-us-button {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #803783;
    border-radius: 6px;
    width: 92px;
    height: 24px;
    gap: 4px;
  }

  .about-us-button p {
    color: white;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 500;
    line-height: 16.25px;
  }

  .about-us-button img {
    width: 16px;
    height: 16px;
  }
}

/* testimonial */

.testimonial-title {
  color: #804c9c;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 0;
  text-align: right;
}

.words-of-appreciation {
  color: #494444;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  text-align: right;
  margin-bottom: 60px;
}

.testimonial-wrapper {
  margin-top: 163px;
}

.testimonial-outer-div {
  width: 608px;
  height: 331px;
  position: relative;
  background: white;
  border-radius: 32px;
  overflow: hidden;
  border: 3px #803783 solid;
  margin-right: 28px;
}

.testimonial-inside {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: flex-start;
}

.testimonial-inside p {
  color: #6d6a6a;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  text-align: center;
  width: 494px !important;
}

.testimonial-inside h6 {
  text-align: right;
  color: #494444;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 18.64px;
  margin-bottom: 6px;
  width: 494px;
}

.testimonial-inside h5 {
  color: #804c9c;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 16.31px;
  text-align: right;
  width: 494px;
}

.testimonial-inside img {
  width: 46px !important;
  height: 41px;
  margin-left: -18px;
}

.home-page-row {
  margin-left: 0 !important;
}

/*doctor message*/

.doctor-message-wrapper {
  width: 122%;
  height: 610px;
  position: relative;
  background: #804c9c;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 64.25px;
  position: relative;
  overflow: hidden;
  margin-left: -137px;
}

.chairman-message-text {
  z-index: 5;
}

.waves-desktop {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.chairman-image {
  border: 14px solid white;
  border-radius: 50%;
  width: 501px;
  height: 501px;
}

.chairman-message-text h2 {
  color: white;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 8px;
}

.chairman-message-text-mobile h2 {
  color: white;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  margin-bottom: 0;
}

.chairman-message-text h3 {
  color: white;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
}

.chairman-message-text-mobile h3 {
  color: white;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
}

.chairman-message-text span {
  color: white;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 25.6px;
  width: 606px;
  height: 312px;
  display: block;
}

.chairman-message-text-mobile span {
  color: white;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 500;
  line-height: 19.2px;
  width: 307px;
  height: 323px;
  display: block;
}

.chairman-message-text img {
  margin-left: -20px;
  margin-bottom: 8px;
}

.chairman-message-text-mobile img {
  margin-top: 25.87px;
  margin-left: -20px;
}

.doctor-message-wrapper-mobile {
  display: none;
}

@media (max-width: 575px) {
  .doctor-message-wrapper {
    flex-direction: column;
  }

  .testimonial-inside {
    align-items: flex-start;
  }

  .chairman-image-mobile {
    width: 61.93px;
    height: 61.93px;
    border: 2.55px solid white;
    border-radius: 50%;
    margin-top: 0 !important;
  }

  .doctor-message-wrapper {
    display: none;
  }

  .doctor-message-wrapper-mobile {
    width: 100%;
    height: 486px;
    position: relative;
    background: #804c9c;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 64.25px;
    flex-direction: column;
    position: relative;
  }

  .waves-mobile {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .chairman-message-text-mobile {
    z-index: 5;
  }

  .about-us-wrapper {
    margin-top: 40px;
  }

  .about-flex {
    flex-direction: column;
  }

  .about-us-image {
    width: 306px;
    height: 204px;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.05) 0%,
      rgba(0, 0, 0, 0.05) 100%
    );
    border-radius: 15.28px;
  }
}

@media (max-width: 1930px) {
  .outer-wrapper-menu {
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .services-desktop {
    margin-left: 27px;
  }

  .about-flex {
    gap: 80px !important;
    justify-content: center;
  }

  .about-diamond {
    margin-left: 50px;
  }

  .testimonial-title-pos {
    margin-right: 90px;
    animation-name: bounceInRight;
  }
}

@media (max-width: 1500px) {
  .outer-wrapper-menu {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important;
  }

  .services-desktop {
    margin-left: 67px;
  }

  .about-diamond {
    margin-left: 135px;
  }

  .about-flex {
    margin-left: 0;
  }

  .testimonial-box {
    margin-left: 32px !important;
  }

  .testimonial-title-pos {
    margin-right: 190px;
    animation-name: bounceInLeft;
  }

  .testimonials-slider.owl-theme .owl-nav .owl-next {
    right: 61px !important;
  }

  .testimonial-outer-div {
    width: 637px !important;
  }
}

@media (max-width: 1450px) {
  .testimonial-outer-division .owl-carousel .owl-stage-outer {
    height: 331px !important;
  }

  .test-outer-wrap .owl-carousel .owl-item {
    width: 624.95px !important;
  }

  .testimonial-outer-div {
    width: 621px !important;
  }
}

@media (max-width: 575px) {
  .about-flex {
    gap: 28px !important;
  }

  .foreign-guests-main {
    padding-top: 0;
  }

  .testimonial-wrapper {
    margin-top: 40px;
  }

  .testimonial-title {
    color: #804c9c;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    text-align: left;
    margin-left: 38px;
  }

  .words-of-appreciation {
    color: #494444;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
    margin-left: 38px;
    width: 300px;
    margin-bottom: 31px;
  }

  .testimonial-outer-div {
    /* width: 313px !important; */
    /* width: 90% !important; */
    width: 81vw !important;
    height: 329px !important;
    margin-right: 0;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .testimonial-inside p {
    color: #6d6a6a;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    width: 253px !important;
    text-align: left;
  }

  .testimonial-inside img {
    width: 16px !important;
    height: 10px !important;
    margin-left: -15px;
  }

  .testimonial-inside h6 {
    color: #6d6a6a;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 13.98px;
    margin-bottom: 5px;
    width: 100%;
  }

  .testimonial-inside h5 {
    color: #804c9c;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 11.65px;
    width: 100%;
  }

  .testimonials-slider.owl-theme .owl-nav .owl-next {
    right: -19px !important;
  }
}

.mobile-carousel {
  width: 100% !important;
}

/*foreign guests*/

.a-medical-tourism {
  color: #804c9c;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  text-align: left;
}

.crossing-borders-for {
  color: #494444;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 60px;
}

.foreign-guest-card {
  width: 1176px;
  height: 346px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 146px;
  background: white;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.11);
  border-radius: 36px;
  justify-content: flex-start;
  align-items: center;
  gap: 46px;
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}

.foreign-image-banner {
  width: 518px;
  height: 298px;
  background: linear-gradient(0deg, #d9d9d9 0%, #d9d9d9 100%);
  border-radius: 22px;
}

.foreign-guests-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.foreign-guest-card h3 {
  color: #494444;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 24px;
}

.foreign-guest-card p {
  color: #494444;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 22.4px;
  margin-bottom: 26px;
}

.foreign-guests-inner button {
  width: 156px;
  height: 38px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  background: #803783;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  border: 0;
  color: white;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 26px;
}

.foreign-guests-inner button:hover {
  width: 159px;
  gap: 8px;
}

.foreign-guests-inner img {
  width: 24px;
  height: 24px;
}

.foreign-title-div {
  width: 1176px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 575px) {
  .photo-of-flex {
    margin-top: 25px !important;
  }

  .hospital-hub-title::before {
    content: "";
    background-image: url("/img/hospital-hub-mobile.svg");
    /* Replace with the actual path to your image */
    background-size: contain;
    /* You can adjust this to your needs */
    background-repeat: no-repeat;
    position: absolute;
    left: 171;
    right: -104px;
    bottom: -168px;
    z-index: -1;
    /* Make sure the background is behind the content */
    opacity: 1;
    width: 190px;
    height: 210px;
  }

  .foreign-guest-card {
    width: 100%;
    height: 415px;
    background: transparent;
    box-shadow: none;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 20.52px;
    margin-bottom: 55px;
  }

  .foreign-image-banner {
    width: 307px;
    height: 184px;
  }

  .foreign-guest-card h3 {
    color: #494444;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    width: 122px;
    margin-bottom: 16.48px;
  }

  .foreign-guest-card p {
    color: #494444;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 400;
    line-height: 19.2px;
    width: 301px;
    margin-bottom: 16px;
  }

  .foreign-title-div {
    width: 307px;
  }

  .a-medical-tourism {
    color: #804c9c;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .crossing-borders-for {
    color: #494444;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 28px;
  }

  .foreign-guests-inner button {
    width: 100px;
    height: 24px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #803783;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    display: inline-flex;
    color: white;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 16.25px;
  }

  .foreign-guests-inner img {
    width: 16px;
    height: 16px;
  }
}

/* happy clients*/

.happy-client-image {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 16px;
}

.our-happy-clients {
  display: inline-block;
  color: #494444;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 0;
}

.hospital-hub-title {
  display: inline-block;
  color: #494444;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 0;
}

.photo-of-flex {
  display: flex;
  align-items: center;
  width: 85%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
  margin-top: 80px;
}

.video-of-flex {
  display: flex;
  align-items: center;
  width: 79%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 24px;
}

.happy-client-image-list {
  width: 250px;
  height: 380px;
  background: linear-gradient(0deg, #d9d9d9 0%, #d9d9d9 100%);
  border-radius: 27.95px;
}

.happy-client-image-list img {
  margin-right: 15px;
}

.total-outer-wrap {
  max-width: 93%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.owl-outer {
  width: 85% !important;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.owl-images {
  width: 292px !important;
  height: 380px !important;
  margin-right: 10px;
  height: auto;
  border-radius: 32px;
  background: linear-gradient(0deg, #d9d9d9 0%, #d9d9d9 100%);
  border-radius: 27.95px;
}

.owl-outer .owl-nav .owl-prev span,
.owl-theme .owl-nav .owl-next span {
  top: -3px;
}

.photo-day-view {
  width: 130px;
  height: 50px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #803783;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  display: inline-flex;
  color: white;
  font-size: 15px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 26px;
  border: none;
}

.photo-day-view-div {
  width: 85%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.blogs-day-view-div {
  width: 78%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.photo-day-view:hover {
  width: 140px;
}

.blogs-day-view-div button {
  margin-right: 20px;
}

.video-day-view-div {
  width: 79%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
}

.video-out-wrapper {
  width: 79%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.video-slider-wrapper {
  margin-top: 80px;
}

.video-slider-video {
  width: 580px !important;
  height: 353px;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  border-radius: 25.97px;
}

.video-image-wrapper {
  width: 474px;
  position: relative;
  cursor: pointer;
}

.video-play-button {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 73px !important;
  height: 73px;
  transform: translate(-50%, -50%);
}

.menu-container-mobile {
  display: none !important;
}

@media (max-width: 1750px) {
  .owl-images {
    width: 266px !important;
  }
}

@media (max-width: 1600px) {
  .owl-images {
    width: 231px !important;
  }
}

@media (max-width: 1750px) {
  .video-slider-video {
    width: 534px !important;
  }
}

@media (max-width: 1600px) {
  .video-slider-video {
    width: 466px !important;
  }
}

@media (max-width: 1500px) {
  .photo-collection-day .owl-carousel .owl-stage-outer {
    height: 380px !important;
  }

  .owl-outer {
    width: 92% !important;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  /* .photo-day-view-div{
    width: 100% !important;
    

  } */

  /* .photo-day-view-div button{
    margin-right: 20px;
  } */

  .blogs-day-view-div button {
    margin-right: 0 !important;
  }

  .owl-images {
    /* width: 236px !important;
    height: 362px !important; */
    width: 248px !important;
    height: 380px !important;
    margin-right: 10px;
    height: auto;
    border-radius: 32px;
    background: linear-gradient(0deg, #d9d9d9 0%, #d9d9d9 100%);
    border-radius: 27.95px;
  }

  .photo-of-flex {
    width: 92%;
  }

  .owl-outer .owl-nav .owl-prev span,
  .owl-theme .owl-nav .owl-next span {
    top: -3px;
  }

  .photo-day-view-div {
    width: 92%;
  }

  .blogs-day-view-div {
    width: 75%;
  }

  .video-out-wrapper {
    width: 85%;
  }

  .video-slider-video {
    width: 479px !important;
  }

  .video-of-flex {
    width: 85%;
  }

  .video-day-view-div {
    width: 85%;
  }
}

@media (max-width: 1450px) {
  .video-slider-video {
    width: 461px !important;
  }
}

@media (max-width: 575px) {
  .news-events-mobile-margin {
    margin-left: 30px !important;
  }

  .photo-collection-day .owl-carousel .owl-stage-outer {
    height: 233px !important;
  }

  .video-out-wrapper .owl-carousel .owl-stage-outer {
    height: 179px !important;
  }

  .testimonial-outer-division .owl-carousel .owl-stage-outer {
    height: 328px !important;
  }

  .stay-connected-outer::before {
    display: none;
  }

  .stay-connected-div::before {
    content: "";
    background-image: url("/img/background-four.svg");
    /* Replace with the actual path to your image */
    background-size: contain;
    /* You can adjust this to your needs */
    background-repeat: no-repeat;
    position: absolute;
    top: 99px;
    left: -15px;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Make sure the background is behind the content */
    opacity: 1;
    width: 202px;
    height: 226px;
  }

  .video-out-wrapper .owl-carousel .owl-item {
    width: 276.5px !important;
  }

  .video-day-view-div {
    width: 100%;
  }

  .video-day-view-div button {
    margin-right: 20px;
  }

  .services-mobile .owl-carousel .owl-stage-outer {
    padding-top: 4px !important;
  }

  .header-mobile {
    display: none;
    width: 16px;
    height: 16px;
  }

  .nav-wrapper .container2 {
    display: none;
  }

  .nav-wrapper .menu-container-mobile {
    display: block !important;
    background: none;
    padding: 0 !important;
  }

  .burger-close {
    position: absolute;
    top: 45px;
    right: 20px;
  }

  .menu a {
    padding-bottom: 4px !important;
  }

  .toggle-btn {
    margin-right: -24px !important;
  }

  span.toggle-lines {
    background: #e64298 !important;
  }

  .toggle-btn.active-toggle {
    background: white !important;
    width: 32px !important;
    height: 32px !important;
  }

  span.toggle-lines {
    width: 27px !important;
  }

  .toggle-close-lines:before,
  .toggle-close-lines:after {
    background: white !important;
  }

  nav.menu {
    background: #802169 !important;
    align-items: flex-start !important;
    padding-left: 26px !important;
    justify-content: flex-start !important;
    padding-top: 150px !important;
  }

  .menu a:hover,
  .menu a.active {
    color: white;
    width: 80% !important;
    border-bottom: 1px rgba(252.01, 120.97, 189.84, 0.5) solid !important;
    text-align: left;
  }

  .menu a {
    width: 80% !important;
    border-bottom: 1px rgba(252.01, 120.97, 189.84, 0.5) solid !important;
    text-align: left;
  }

  span.toggle-lines:before,
  span.toggle-lines:after {
    background: #e64298 !important;
  }

  .nav-contact {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .nav-contact img {
    width: 14px;
    height: 14px;
    margin-top: -2px;
  }

  .nav-contact h4 a {
    margin-bottom: 0 !important;
    color: white;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    border: none !important;
  }

  .nav-mail {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .nav-mail img {
    width: 14px;
    height: 14px;
    margin-top: -2px;
  }

  .nav-mail h4 a {
    margin-bottom: 0 !important;
    color: white;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    border: none !important;
  }

  .nav-mail h4 {
    margin-bottom: 0 !important;
  }

  .nav-contact h4 {
    margin-bottom: 0 !important;
  }

  .nav-phone-email {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: absolute;
    left: 28px;
    bottom: 28px;
  }

  .mobile-stars {
    width: 20px;
    height: 30px;
    margin-bottom: 30px;
    margin-left: -5px;
    display: block !important;
  }

  .stay-connected-outer {
    margin-bottom: 29px !important;
  }

  .owl-images {
    width: 161px !important;
    height: 233px !important;
    border-radius: 17.16px;
  }

  .owl-outer .owl-nav .owl-prev {
    display: none;
  }

  .owl-outer .owl-nav .owl-next {
    display: none;
  }

  .happy-client-image {
    width: 4.78px;
    height: 4.78px;
    margin-right: 10px;
  }

  .our-happy-clients {
    color: #494444;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
  }

  .hospital-hub-title {
    color: #494444;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
  }

  .photo-of-flex {
    margin-bottom: 16px;
  }

  .photo-day-view {
    width: 89px;
    height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #803783;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    display: inline-flex;
    color: white;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 16.25px;
  }

  .photo-day-view-div {
    margin-top: 27.23px;
  }

  .photo-day-view img {
    width: 16px;
    height: 16px;
  }

  .total-outer-wrap {
    margin-top: 25px;
  }
}

@media (max-width: 380px) {
  nav.menu {
    padding-top: 120px !important;
  }

  .menu a {
    margin-bottom: 5px !important;
  }
}

/*blogs*/

.blog-outer-div {
  width: 327px;
  height: 455px;
  position: relative;
  background: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
  border-radius: 32px;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: inline-flex;
  padding: 12px 0;
}

.news-events-arrow.owl-theme .owl-nav .owl-next {
  right: 4px;
}

.news-events-arrow.owl-theme .owl-nav .owl-prev {
  left: -23px;
}

.owl-theme .owl-nav .owl-prev span,
.owl-theme .owl-nav .owl-next span {
  top: -3px !important;
}

.news-events-outer-div .owl-carousel .owl-item {
  height: 500px;
  margin-top: 10px;
}

.news-events-outer-div {
  width: 76.2% !important;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
}

.stay-connected-div {
  padding-left: 30px !important;
  animation-name: bounceInRight;
}

.blog-outer-div img {
  width: 303px !important;
  height: 224px !important;
  background: linear-gradient(0deg, #d9d9d9 0%, #d9d9d9 100%);
  border-radius: 23px;
  margin-bottom: 24px;
}

/* .blog-outer-div button {
  width: 280px;
  height: 42px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 32px;
  border: 2px #804c9c solid;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  background: transparent;
  color: #804c9c;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 26px;
  transition: all 0.3s;
} */

.hover-button-blogs {
  width: 280px;
  height: 42px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 32px;
  border: 2px #804c9c solid !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
  background: transparent;
  color: #804c9c;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 26px;
  transition: all 0.3s;
}

.hover-button-blogs p {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 26px;
  transition: all 0.3s;
  color: #804c9c;
}

/* .blog-outer-div button:hover {
  background: #e64298;
  color: white;
} */

.hover-button-blogs:hover {
  background: #881450 !important;
}

.hover-button-blogs:hover p {
  color: white !important;
}

.blog-outer-div button img {
  width: 24px !important;
  height: 24px !important;
  background: none;
  border-radius: 0 !important;
  margin-bottom: 0 !important;
}

.diagnose-male {
  color: #804c9c;
  font-size: 18px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 20.97px;
  width: 268px;
  margin-bottom: 12px;
  height: 42px;
}

.male-infertility {
  color: #494444;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 300;
  width: 279px;
  height: 88px;
}

.news-events-titles h3 {
  color: #804c9c;
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  margin-bottom: 0;
  text-align: left;
}

.news-events-titles h2 {
  color: #494444;
  font-size: 40px;
  font-family: Poppins;
  font-weight: 600;
  right: 0;
  text-align: left;
}

.news-events-titles {
  width: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-right: 20px;
  padding-right: 0px;
}

.stay-connected-div {
  width: 493px;
}

.stay-connected-outer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.stay-connected-diamond {
  width: 56px;
  height: 85px;
  margin-top: 50px;
  margin-left: -23px;
}

.photo-day-view-div img ::before {
  display: none;
}

.photo-day-view-div img ::after {
  display: none;
}

.photo-day-view-div ::before {
  width: 459px;
  height: 480px;
  content: "";
  background-image: url("/img/hospital-hub-pattern.svg");
  /* Replace with the actual path to your image */
  background-size: contain;
  /* You can adjust this to your needs */
  background-repeat: no-repeat;
  position: absolute;
  top: -108px;
  left: 1213px !important;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* Make sure the background is behind the content */
  opacity: 1;
}

.footer-contact-area::after {
  display: none;
}

.footer-email-area::after {
  display: none;
}

.mobile-stars {
  display: none;
}

@media (max-width: 1750px) {
  .blog-outer-div {
    width: 319px;
  }
}

@media (max-width: 1550px) {
  .photo-day-view-div ::before {
    left: 952px !important;
  }

  .blog-outer-div {
    width: 327px;
    height: 455px;
    position: relative;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.11);
    border-radius: 32px;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    padding: 12px 0;
  }
}

@media (max-width: 1500px) {
  .photo-collection-day .owl-carousel .owl-item {
    width: 288px !important;
  }

  .photo-day-view-div ::before {
    left: 1005px !important;
  }

  .photo-day-view-div ::after {
    left: 869px;
  }
}

.foreign-title-div ::before {
  content: "";
  background-image: url("/img/star-right.svg");
  /* Replace with the actual path to your image */
  background-size: cover;
  /* You can adjust this to your needs */
  position: absolute;
  top: 0;
  right: -35px;
  bottom: 0;
  z-index: -1;
  /* Make sure the background is behind the content */
  width: 83px;
  height: 109px;
}

/* .photo-collection-day .owl-carousel .owl-item{
  width: 240px !important;
} */

@media (max-width: 1750px) {
  .news-events-arrow.owl-theme .owl-nav .owl-next {
    right: -19px;
  }
}

@media (max-width: 1600px) {
  .blogs-day-view-div button {
    margin-right: 40px;
  }

  .news-events-arrow.owl-theme .owl-nav .owl-next {
    right: 18px;
  }
}

@media (max-width: 1500px) {
  .news-events-arrow.owl-theme .owl-nav .owl-next {
    right: 4px;
  }

  .news-events-outer-div {
    width: 75%;
  }
}

@media (max-width: 575px) {
  .stay-connected-outer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
  }

  .stay-connected-diamond {
    display: none;
  }

  .news-events-outer-div {
    width: 100% !important;
  }

  .photo-collection-day .owl-carousel .owl-item {
    width: 174.5px !important;
  }

  .video-slider-video {
    width: 266px !important;
    height: 180px !important;
  }

  .total-outer-wrap {
    max-width: 100% !important;
  }

  .photo-of-flex {
    width: 100% !important;
    margin-left: 30px;
  }

  .video-out-wrapper {
    width: 100%;
    margin-left: 30px;
  }

  .photo-collection-day .owl-carousel {
    width: 100% !important;
    margin-left: 30px;
    height: 233px;
  }

  .video-image-wrapper {
    width: 266px !important;
  }

  .stay-connected-div h3 {
    color: #804c9c;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    text-align: left;
  }

  .stay-connected-div h2 {
    color: #494444;
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 28px;
    text-align: left;
  }

  .news-events-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-top: 25px;
  }

  .service-testimonial-wrapper {
    padding-bottom: 35px;
  }

  .blog-outer-div {
    width: 209px;
    height: 395px;
  }

  .blog-image img {
    width: 199px !important;
    height: 139px !important;
  }

  .diagnose-male {
    color: #804c9c;
    font-size: 14px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 16.31px;
    width: 182px;
    height: 32px;
    text-align: center;
  }

  .male-infertility {
    color: #494444;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 300;
    width: 177px;
    height: 134px;
    margin-bottom: 0;
  }

  /* .blog-outer-div button {
    width: 184px;
    height: 31px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50px;
    border: 1px #804c9c solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    color: #804c9c;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 19.2px;
  } */

  .hover-button-blogs {
    width: 184px;
    height: 31px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-radius: 50px;
    border: 1px #804c9c solid;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    color: #804c9c;
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 19.2px;
  }

  .hover-button-blogs p {
    font-size: 12px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 19.2px;
    color: #804c9c;
  }

  .blog-outer-div button img {
    width: 12px !important;
    height: 10px !important;
  }

  .news-events-outer-div {
    width: 89%;
  }

  .news-events-outer-div .owl-carousel .owl-item {
    width: 220px !important;
  }

  .news-events-arrow.owl-theme .owl-nav .owl-prev {
    display: none;
  }

  .news-events-arrow.owl-theme .owl-nav .owl-next {
    display: none;
  }

  .news-events-outer-div .owl-carousel .owl-item {
    height: 421px !important;
  }

  .blogs-day-view-div {
    margin-top: 20px;
    width: 100%;
  }

  .blogs-day-view-div button {
    margin-right: 20px !important;
  }

  .video-slider-wrapper {
    margin-top: 28px;
  }
}

/*testimonial*/

.testimonial-outer-division {
  width: 100%;
  margin-top: 163px;
  margin-bottom: 140px;
}

@media (max-width: 575px) {
  .testimonial-outer-division {
    margin-top: 80px;
    margin-bottom: 0px;
    position: relative;
  }

  .foreign-title-div ::before {
    content: "";
    background-image: url(/img/star-right.svg);
    background-size: cover;
    position: absolute;
    top: 17px;
    right: -22px;
    bottom: 0;
    z-index: -1;
    width: 32px;
    height: 42px;
  }
}

.test-outer-wrap .owl-theme .owl-nav .owl-next {
  right: -8px !important;
}

@media (max-width: 1500px) {
  .test-outer-wrap {
    width: 90%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .test-outer-wrap .owl-theme .owl-nav .owl-next {
    right: -13px !important;
  }

  .test-outer-wrap .owl-carousel .owl-item {
    /* width: 647px !important; */
  }
}

@media (max-width: 1500px) {
  .test-outer-wrap .owl-carousel .owl-item {
    width: 641px !important;
  }
}

@media (max-width: 1450px) {
  .test-outer-wrap .owl-carousel .owl-item {
    width: 643px !important;
  }
}

@media (max-width: 575px) {
  .test-outer-wrap .owl-carousel .owl-item {
    width: 351px !important;
  }

  .test-outer-wrap .owl-theme .owl-nav .owl-prev {
    left: -4px !important;
    background-image: url("/image/slider-arrow-right.svg");
  }

  .test-outer-wrap .owl-theme .owl-nav .owl-next {
    right: -3px !important;
  }

  /* .words-of-appreciation::before{
    content: "";
    background-color: #FCECF4;
    border-radius: 50%;
    background-size: cover;
    position: absolute;
    top: 1830px;
    left: 58px;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 1;
    width: 78px !important;
    height: 78px !important;
  } */

  .mobile-carousel::after {
    display: none;
  }

  .mobile-carousel::before {
    display: none;
  }

  /* .testimonial-background::before{
    content: "";
    background-image: url(/img/background-one.svg);
    background-size: cover;
    position: absolute;
    top: 1860px;
    left: -85px;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: 1;
    width: 267px;
    height: 267px;
  } */
}

@media (max-width: 420px) {
  .test-outer-wrap .owl-carousel .owl-item {
    width: 90vw !important;
  }
}

@media (max-width: 392px) {
  .test-outer-wrap .owl-carousel .owl-item {
    width: 90vw !important;
  }
}

@media (max-width: 370px) {
  .test-outer-wrap .owl-carousel .owl-item {
    /* width: 325px !important; */
    width: 90vw !important;
  }
}

/* ffoter */

.footer-cont {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 6px;
}

.footer-cont img {
  width: 11px;
  height: 11px;
}

.footer-cont h4 {
  margin-bottom: 0;
  color: white;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 300;
}

.footer-address {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 6px;
}

.footer-address img {
  width: 11px;
  height: 11px;
}

.footer-address h4 {
  color: white;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 300;
  margin-bottom: 0;
}

.footer-addr {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 6px;
}

.footer-addr img {
  width: 11px;
  height: 11px;
  object-fit: contain !important;
}

.footer-addr h4 {
  color: white;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 300;
  margin-bottom: 0;
  width: 220px;
}

.footer-social-icons {
  border-top: 1px solid #535657;
  margin-top: 20px;
  padding-top: 8px;
  display: flex;
  gap: 10px;
}

.developed-by {
  display: flex;
  justify-content: center;
}

.developed-by span {
  text-align: center;
  color: white;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 26px;
  cursor: auto;
}

.riolabz-title {
  cursor: pointer !important;
}

@media (max-width: 1500px) {
  .footer-containers {
    padding-left: 0;
    padding-right: 0;
    max-width: 1400px;
  }
}

@media (max-width: 575px) {
  .footer-phone-email {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    align-items: center;
  }

  .footer-contact-area {
    width: 224px;
    height: 55px !important;
    min-height: 55px;
    border-radius: 20px !important;
  }

  .footer-phone-main img {
    width: 27px;
    height: 27px;
  }

  .footer-contact-content p {
    color: #494444;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 400;
  }

  .footer-contact-content h4 a {
    color: #494444 !important;
    font-size: 12px !important;
    font-family: Poppins !important;
    font-weight: 700 !important;
  }

  .footer-phone-main {
    justify-content: center !important;
  }

  .footer-mail-main {
    justify-content: center !important;
  }

  .footer-email-area {
    width: 290px;
    height: 53px;
    min-height: 53px;
    border-radius: 20px;
  }

  .footer-mail-main img {
    width: 26px;
    height: 26px;
  }

  .footer-mail-main {
    margin-top: 0 !important;
  }

  .footer-contact-wrapper {
    margin: 0 0 0;
    position: relative;
    background: #373737;
    padding: 32px 0 0 0;
  }

  .footer-email-area::after {
    display: none;
  }

  .footer-contact-area::after {
    display: none;
  }

  .footer-outer-wrapper {
    margin-left: 20px;
  }

  .copyright h6 {
    text-align: center;
    width: 241px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  footer {
    padding-top: 48px;
  }
}

.mobile-carousel::before {
  content: "";
  background-image: url("/img/testimonial-pattern.svg");
  /* Replace with the actual path to your image */
  background-size: contain;
  /* You can adjust this to your needs */
  background-repeat: no-repeat;
  position: absolute;
  top: -228px;
  left: -219px;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* Make sure the background is behind the content */
  opacity: 1;
  width: 438px;
  height: 500px;
}

.a-medical-tourism::after {
  content: "";
  background-image: url("/img/cross-pattern.svg");
  /* Replace with the actual path to your image */
  background-size: fill;
  /* You can adjust this to your needs */
  background-repeat: no-repeat;
  position: absolute;
  top: 182px;
  left: -346px;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* Make sure the background is behind the content */
  opacity: 1;
  width: 398px;
  height: 450px;
}

/* .mobile-carousel::after{
  content: "";
  background-color: #FCECF4;
  border-radius: 50%;
  background-size: cover; 
  position: absolute;
  top: -260px;
  left: 164px;
  right: 0;
  bottom: 0;
  z-index: -1; 
  opacity: 0.7;
  width: 128px;
  height: 128px;

} */

.appointment-report {
  display: none !important;
}

@media (max-width: 575px) {
  .appointment-report {
    display: block !important;
  }

  .a-medical-tourism::after {
    content: "";
    background-image: url("/img/medical-tourism-mobile.svg");
    /* Replace with the actual path to your image */
    background-size: contain;
    /* You can adjust this to your needs */
    background-repeat: no-repeat;
    position: absolute;
    top: 522px;
    left: -29px;
    right: 0;
    bottom: 0;
    z-index: -1;
    /* Make sure the background is behind the content */
    opacity: 1;
    width: 214px;
    height: 238px;
  }
}

.appointment-report {
  position: fixed;
  bottom: 0;
  display: flex;
  width: 100%;
  color: white;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  border-top-left-radius: 16px;
  z-index: 999999999999999999;
}

.lab-rep-button {
  color: white;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  border-top-left-radius: 16px;
  background: #804c9c;
  border: none;
  padding: 15px;
  border-right: 1px #f757ab solid;
  width: 40%;
}

.book-app-button {
  color: white;
  font-size: 14px;
  font-family: Poppins;
  font-weight: 600;
  border-top-right-radius: 16px;
  background: #804c9c;
  border: none;
  padding: 15px;
  width: 60%;
}

.visible-button {
  opacity: 1;
}

.hidden-button {
  opacity: 0;
  pointer-events: none;
  /* This prevents interactions when hidden */
}

.testimonial-button-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.testimonial-view-all {
  width: 153px;
  height: 50px;
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #803783;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 12px;
}

.testimonial-view-all:hover {
  width: 162px;
}

.testimonial-view-all span {
  color: white;
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 26px;
}

@media (max-width: 1500px) {
  .testimonial-view-all {
    margin-right: 25px;
  }
}

@media (max-width: 575px) {
  .testimonial-view-all:hover {
    width: 153px !important;
  }

  .testimonial-view-all {
    width: 91px;
    height: 24px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #803783;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-right: 18px;
  }

  .testimonial-view-all span {
    color: white;
    font-size: 10px;
    font-family: Poppins;
    font-weight: 600;
    line-height: 16.25px;
  }

  .testimonial-view-all img {
    width: 16px;
    height: 16px;
  }
}

.owl-prev,
.owl-next {
  background-image: url("/image/slider-arrow-right.svg") !important;
  /* Path to your left arrow image */
  background-size: contain;
}

.news-events-wrapper .owl-carousel .owl-stage-outer {
  height: 467px !important;
}

@media (max-width: 575px) {
  .news-events-wrapper .owl-carousel .owl-stage-outer {
    height: 407px !important;
  }
}

@media (max-width: 575px) {
  .video-out-wrapper .owl-theme .owl-nav .owl-prev {
    display: none !important;
  }

  .footer-phone-main,
  .footer-mail-main {
    padding: 0 28px !important;
  }

  .footer-email-area {
    padding: 0 22px 0 20px;
  }

  .footer-head {
    padding-left: 0;
  }

  .footer-menu ul {
    padding-left: 10px;
  }

  .footer-social-icons {
    margin-top: 25px;
    padding-top: 15px;
  }
}

#custom-prev,
#custom-next {
  width: 28px;
  /* Set your desired width */
  height: 28px;
  /* Set your desired height */
  opacity: 1;
  visibility: visible;
}

.test-outer-wrap .owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev:hover {
  background-color: white !important;
}

/* testimonials */

.mob-testimonial {
  display: none;
}

.mob-testimonials {
  display: none;
}

@media (max-width: 575px) {
  .mob-testimonials {
    display: block;
  }

  .mob-testimonial {
    display: block;
  }

  .testimonial-button-div {
    margin-bottom: 80px;
  }
}

.mob-comments-wrapper {
  width: 80%;
  height: 329px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 16px;
  padding: 43px 25px 21px 30px;
  border: 2px #e64298 solid;
}

.mob-comments-wrapper img {
  width: 10px !important;
  height: 10px;
  margin-left: -8px;
}

.test-right-arrow {
  position: fixed;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 1.559999942779541px 3.119999885559082px rgba(0, 0, 0, 0.25);
}

.test-right-arrow::after {
  content: "";
  background-image: url("/img/arrow-button-right.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 22px;
  height: 22px;
}

.arrow-right-inside {
  width: 100%;
  height: 100%;
}

.test-left-arrow {
  position: fixed;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 1.559999942779541px 3.119999885559082px rgba(0, 0, 0, 0.25);
}

.test-left-arrow::after {
  content: "";
  background-image: url("/img/arrow-button-left.svg");
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 22px;
  height: 22px;
}

.arrow-left-inside {
  width: 100%;
  height: 100%;
}

.mob-testimonial .owl-theme .owl-nav .owl-prev {
  display: none !important;
}

.mob-testimonial-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
}

.mob-testimonial-div p {
  color: #6d6a6a;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  margin-bottom: 18px;
  width: 226px;
}

.mob-testimonial-div h6 {
  width: 100%;
  text-align: right;
  color: #6d6a6a;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 13.98px;
  margin-bottom: 5px;
}

.mob-testimonial-div h5 {
  width: 100%;
  text-align: right;
  color: #e64298;
  font-size: 10px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 11.65px;
}

.mob-test-view {
  display: flex;
  gap: 4px;
  width: 91px;
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: #e64298;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border: none;
}

.mob-test-view span {
  color: white;
  font-size: 10px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 16.25px;
}

.mob-test-view img {
  width: 16px;
  height: 16px;
}

.mob-test-div {
  width: 80vw;
  position: relative;
  left: 50vw;
  transform: translateX(-50%);
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 80px;
}

.selected-menu {
  text-transform: uppercase;
  font-size: 15px;
  margin: 0 16px 0 8px;
  font-weight: 700 !important;
  position: relative;
  transition: ease 1s;
  text-shadow: 1px 1px 1px #3f3f41;
}

.no-spin-buttons::-webkit-inner-spin-button,
.no-spin-buttons::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

#formAge::-webkit-inner-spin-button,
#formAge::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

.card-active-outer {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 9999px;
  border: 2px #d5d5d5 solid;
  padding: 3px;
  width: 16px;
  height: 16px;
}

.select-profile-popup .modal-content {
  width: 80% !important;
}

.select-profile-popup .modal.show .modal-dialog {
  width: 80% !important;
}

.select-profile-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

/* .doc-list-doctor {
  margin-bottom: 80px !important;
} */

.department-total-wrap {
  margin-bottom: 80px;
}

.services-aside-list {
  flex-direction: column;
}

@media (max-width: 575px) {
  .department-total-wrap {
    margin-bottom: 6px !important;
  }

  .news-evnts-margin,
  .gallery-margin,
  .gallery-mobile-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.testimonial-pattern-mobile {
  display: none;
}

@media (max-width: 575px) {
  .testimonial-pattern-mobile {
    display: block;
    position: absolute;
    width: 267px;
    height: 267px;
    z-index: -2;
    top: 33px;
    left: -97px;
  }
}

.service-bubbles {
  position: absolute;
  right: 31px;
  top: 46px;
}

.news-ev-pattern {
  width: 438px;
  height: 492px;
  position: absolute;
  left: -216px;
  top: -87px;
  z-index: -1;
}

.service-wrapper-mobiles {
  display: none;
}

@media (max-width: 575px) {
  .service-bubbles {
    display: none;
  }

  .news-ev-pattern {
    display: none;
  }

  .stay-connected-div {
    animation-name: bounceInLeft;
  }

  .desktop-service-list {
    display: none;
  }

  .service-wrapper-mobiles {
    display: block;
    width: 100%;
    height: 303px;
    overflow: hidden;
    padding-top: 7px;
  }

  .service-wrapper-mobiles .owl-theme .owl-nav .owl-next {
    display: none !important;
  }

  .service-wrapper-mobiles .owl-carousel .owl-stage-outer {
    padding-top: 6px;
    padding-left: 6px;
  }

  .mobile-contact-us {
    padding-left: 15px;
    padding-right: 15px;
  }

  .contact-info-details {
    width: 100%;
  }

  .success-stories-section {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .news-events-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .news-event-cards,
  .banner-title-element {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .r-tabs .r-tabs-panel {
    padding: 15px 0 0 0 !important;
  }

  .r-tabs .r-tabs-nav {
    padding: 0 !important;
  }

  .video-list-comment,
  .department-total-wrap {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .career-card-list,
  .gallery-card-list,
  .doctor-wrapper-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

/* .blogs-ind-card{
  width: 300px;
  height: 380px;

} */

#blogsIndCard {
  width: 286px;
  height: 419px;
}

#blogsIdCard {
  width: 286px;
  height: 419px;
}

@media (max-width: 575px) {
  .blogs-ind-card {
    padding-top: 0px;
  }
}

.doc-qualification-hide {
  opacity: 0;
  height: 14px;
  margin-bottom: 0;
  width: 100%;
}

#doctors-popup .modal-content {
  width: 90% !important;
}

.contact-padding {
  padding-right: 8px;
}

#contactUploadForm {
  padding: 7px 15px !important;
}

@media (max-width: 575px) {
  #doctors-popup .modal-content {
    width: 100% !important;
  }

  .contact-padding {
    padding-right: 0px;
  }

  .doc-qualification-hide {
    opacity: 0;
    height: 14px;
    margin-bottom: 0;
  }

  .react_lightgallery_item {
    width: 93% !important;
  }
}

.blog-title-wrapper {
  height: 89px;
}

.blog-cards-description {
  height: 57px;
}

.blog-id-title {
}

#blogsIdImage {
  height: 181px;
}

.pagination li.active {
  color: white !important;
}

.pagination li {
  width: 40px !important;
  height: 40px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px !important;
}

.slider-outer .slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.slider-outer .slick-initialized .slick-slide {
  padding-top: 4px !important;
  height: 460px;
}

.doc-banner {
  width: 84.416% !important;
  max-width: 1920px !important;
  padding: 0 !important;
}

.doc-detail-banner {
  padding: 0 !important;
  width: 82.525% !important;
  max-width: 1920px !important;
}

@media (max-width: 1600px) {
  .doc-banner {
    width: 1271px !important;
  }

  .doc-detail-banner {
    width: 83% !important;
  }
}

@media (max-width: 1500px) {
  .doc-banner {
    width: 1209px !important;
  }
}

@media (max-width: 720px) {
  .doc-detail-banner {
    padding: 0 !important;
    width: 307px !important;
  }

  .doc-banner {
    width: 288px !important;
    padding: 0 !important;
  }
}

@media (max-width: 370px) {
  .doc-detail-banner {
    padding: 0 !important;
    width: 90vw !important;
  }
}

.doc-banner-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.doc-detail-banner-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.phone-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.phone-number {
  /* color: #803783; */
  color: #804c9c;
  /* font-family: Poppins; */
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
